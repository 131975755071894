import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import Slide from './Slide';
import { updateObjectHandler } from '../../utils/functions';
import { showNotification } from '../../App';
import { get } from '../../utils/idb-config';
import './styles.scss';

const Customize = ({ id, setShow, slides }) => {
  const presentations = useSelector((state) => state.presentation.presentations) || [];
  const [allChecked, setAllChecked] = useState(true);
  const [presentationsSave, setPresentationsSave] = useState(slides);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (slides.length === presentationsSave.length) setAllChecked(true);
  }, [slides, presentationsSave]);

  const saveHandler = async () => {
    const localPresentations = (await get('presentations')) || [];

    const data = localPresentations.length
      ? localPresentations.filter((item) => item.data.id === id)[0]?.data
      : presentations?.filter((item) => item.data.id === id)[0]?.data;

    if (title.trim().length > 3 && presentationsSave.length) {
      data.name = title;
      data.id = new Date().toISOString();

      await updateObjectHandler('custom', {
        data,
        slides: presentationsSave.sort((a, b) => parseInt(a.position) - parseInt(b.position)),
      });

      setShow(false);
      showNotification('success', 'Your customized presentation has been saved.', 'Custom');
      setTitle('');
    } else {
      showNotification('error', 'Title minimum 3 character', 'Custom');
    }
  };

  return (
    <div className="presentation-customize">
      <div className="presentation-customize-header">
        <ArrowLeftOutlined className="presentation-customize-icon" onClick={() => setShow(false)} />
        <h2>Custom PRESENTATION</h2>
        <button onClick={saveHandler}>Save</button>
      </div>
      <div className="presentation-customize-body">
        <div className="presentation-customize-search">
          <h2>Title:</h2>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
          <label>
            <span>All</span>
            <input type="checkbox" checked={allChecked} onChange={(e) => setAllChecked(e.target.checked)} />
          </label>
        </div>
        <ul className="presentation-customize-list">
          {slides.length
            ? slides.map((slide, index) => (
                <Slide key={index} slide={slide} allChecked={allChecked} setPresentationsSave={setPresentationsSave} />
              ))
            : null}
        </ul>
      </div>
    </div>
  );
};

export default Customize;
