import React, { useRef, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside';
import { logout } from '../../store/actions/authActions';
import { useDispatch } from 'react-redux';
import { useFonts } from '../../utils/hooks/useFonts';
import { showNotification } from '../../App';
import './styles.scss';

const SettingsModal = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const { fonts, setFonts } = useFonts();
  useOnClickOutside(ref, () => setShow(false));
  const [currentSize, setCurrentSize] = useState(fonts);

  const changeHandler = (event) => {
    setCurrentSize(event.target.name);
  };

  const saveFontsHandler = () => {
    setFonts(currentSize);
    showNotification('success', 'Save Fonts Size Successfully', 'Success');
    setShow(false);
  };

  return (
    <>
      {show ? (
        <div className="settings__modal" ref={ref}>
          <div className="settings__modal-header">
            <ArrowLeftOutlined className="settings__modal-icon" onClick={() => setShow(false)} />
            <h3>Application Settings</h3>
          </div>
          <div className="settings__modal-body">
            <div className="settings__modal-block">
              <p>Username</p>
              <button className="settings__modal-btn" onClick={() => dispatch(logout())}>
                Logout
              </button>
            </div>

            <div className="settings__modal-block">
              <p style={{ marginBottom: '5px' }}>PDF Emails</p>
              <button className="settings__modal-btn">Send</button>
            </div>

            <h4>Pending emails: 0</h4>
            <div className="settings__modal-fonts">
              <h6>Notes Font Size:</h6>
              <div className="settings__modal-fonts-list">
                <label>
                  <input name="small" checked={'small' === currentSize} type="radio" onChange={changeHandler} />
                  Small
                </label>
                <label>
                  <input name="medium" checked={'medium' === currentSize} type="radio" onChange={changeHandler} />
                  Medium
                </label>
                <label>
                  <input name="large" checked={'large' === currentSize} type="radio" onChange={changeHandler} />
                  Large
                </label>
                <label>
                  <input name="extra-large" checked={'extra-large' === currentSize} type="radio" onChange={changeHandler} />
                  Extra Large
                </label>
                <label>
                  <input name="huge" checked={'huge' === currentSize} type="radio" onChange={changeHandler} />
                  Huge
                </label>
              </div>
              <button className="settings__modal-btn" onClick={saveFontsHandler}>
                Save
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SettingsModal;
