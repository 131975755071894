import { get, set, update } from './idb-config';
import axios from 'axios';
import { imgURL } from './config';
import _ from 'lodash';
import { controller } from './axiosController';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorage';

export const sliceString = (width, name = '', sliceXL = 35, sliceL = 20, sliceS = 15) => {
  return width >= 1800
    ? name.length > sliceXL
      ? name.slice(0, sliceXL) + '...'
      : name
    : width >= 1600
    ? name.length > sliceL
      ? name.slice(0, sliceL) + '...'
      : name
    : name.length > sliceS
    ? name.slice(0, sliceS) + '...'
    : name;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const toDataURL = async (url, callback) => {
  try {
    const response = await axios.get(
      url,
      {
        responseType: 'blob',
      },
      { signal: controller.signal }
    );
    callback(response.data);
  } catch (e) {
    callback(null);
  }
};

export const setHandler = async (db, array) => {
  try {
    await get(db).then((val) => {
      if (val) update(db, () => array);
      else set(db, array);
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateObjectHandler = async (db, obj) => {
  try {
    await get(db).then((val) => {
      if (val) update(db, (update) => [...update, obj]);
      else set(db, [obj]);
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateArrayHandler = async (db, array) => {
  try {
    await get(db).then((val) => {
      if (val)
        update(db, (update) => {
          return _.uniqBy([...update, ...array], 'id');
        });
      else set(db, array);
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateArrayHandlerLocalStorage = (array) => {
  try {
    const files = loadFromLocalStorage('files-all-folders') || [];

    saveToLocalStorage('files-all-folders', _.uniqBy([...files, ...array], 'id'));
  } catch (e) {
    console.error(e);
  }
};

export function toDataURLAsync(url) {
  return new Promise((resolve) => {
    toDataURL(url, (dataUrl) => {
      resolve(dataUrl);
    });
  });
}

export const convertImageUrlToBase64 = (imageUrl) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();

    image.crossOrigin = 'Anonymous';
    image.onload = function () {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };

    image.onerror = function () {
      reject('');
    };

    image.src = imageUrl;
  });
};

export const pdfToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
};
export const presentationsHandler = async (presentations = []) => {
  const processedPresentations = [];

  for (const item of presentations) {
    item.data.localUrl = await toDataURLAsync(imgURL + item?.data?.mobile_thumbnail);
    convertImageUrlToBase64(imgURL + item?.data?.mobile_thumbnail).then((response) => {
      item.data.localUrlBase64 = response;
    });

    if (item?.e_detailer && item?.e_detailer?.logo) {
      item.e_detailer.localLogo = await toDataURLAsync(imgURL + item?.e_detailer?.logo);

      convertImageUrlToBase64(imgURL + item?.e_detailer?.logo).then((response) => {
        item.e_detailer.localLogoBase64 = response;
      });
    }

    for (let i = 0; i < item.slides.length; i++) {
      if (item.slides[i].type === 'video') {
        const previewUrl = item.slides[i].preview_url;
        item.slides[i].localPreview = await toDataURLAsync(imgURL + previewUrl);

        const data = await toDataURLAsync(imgURL + item.slides[i].url);
        item.slides[i].localUrlBase64 = await pdfToBase64(data);
      }

      if (item.slides[i]?.reference_type === 'image') {
        const referenceContent = item.slides[i]?.reference_content;
        item.slides[i].referenceLocalUrl = await toDataURLAsync(imgURL + referenceContent);

        convertImageUrlToBase64(imgURL + referenceContent).then((response) => {
          item.slides[i].referenceLocalUrlBase64 = response;
        });
      }

      const url = item.slides[i].type === 'pdf' ? item.slides[i].preview_url : item?.slides[i].url;
      item.slides[i].localUrl = await toDataURLAsync(imgURL + url);

      if (item.slides[i].type !== 'pdf' && item.slides[i].type !== 'video') {
        convertImageUrlToBase64(imgURL + url).then((response) => {
          item.slides[i].localUrlBase64 = response;
        });
      }
    }
    item.flag = 'Presentation';
    processedPresentations.push(item);
  }

  return processedPresentations;
};

export const clinicalPapersHandler = async (clinicalPapers = []) => {
  const array = [];

  if (clinicalPapers.length) {
    for (let i = 0; i < clinicalPapers.length; i++) {
      const files = [];

      clinicalPapers[i].localUrl = await toDataURLAsync(imgURL + clinicalPapers[i].thumbnail);
      clinicalPapers[i].flag = 'Clinical Paper Folders';

      convertImageUrlToBase64(imgURL + clinicalPapers[i].thumbnail).then((response) => {
        clinicalPapers[i].localUrlBase64 = response;
      });

      for (let j = 0; j < clinicalPapers[i].files.length; j++) {
        clinicalPapers[i].files[j].localUrl = await toDataURLAsync(imgURL + clinicalPapers[i].files[j].thumbnail);
        convertImageUrlToBase64(imgURL + clinicalPapers[i].files[j].thumbnail).then((response) => {
          clinicalPapers[i].files[j].localUrlBase64 = response;
        });
        const data = await toDataURLAsync(imgURL + clinicalPapers[i].files[j].path);

        clinicalPapers[i].files[j].localPath = data;
        clinicalPapers[i].files[j].localPathBase64 = await pdfToBase64(data);
        clinicalPapers[i].files[j].flag = 'Clinical Paper';
        files.push(clinicalPapers[i].files[j]);
      }

      await setHandler(`files-clinical-papers`, files);
      updateArrayHandlerLocalStorage(files);
      array.push({ ...clinicalPapers[i], files });
    }
  }

  return array;
};

export const foldersHandler = async (folders = []) => {
  const files = [];

  if (folders.length) {
    for (let i = 0; i < folders.length; i++) {
      folders[i].localUrl = await toDataURLAsync(imgURL + folders[i].thumbnail);

      convertImageUrlToBase64(imgURL + folders[i].thumbnail).then((response) => {
        folders[i].localUrlBase64 = response;
      });

      files.push(folders[i]);
    }
  }

  return files;
};

export const compareNumbers = (a, b) => {
  if (a.value === undefined) a.value = 0;
  if (b.value === undefined) b.value = 0;

  if (a.value < b.value) {
    return 1;
  }
  if (a.value > b.value) {
    return -1;
  }
  return 0;
};

export const starsRatingLocal = (array = [], stars = []) => {
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < stars.length; j++) {
      if (array[i]?.data?.id === stars[j].id || array[i].id === stars[j].id) {
        array[i].value = stars[j].value;
      }
    }
  }

  return array;
};
