import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFileByFolder, getFolders } from '../../store/actions/foldersActions';
import Card from '../../Components/Card.js/Card';
import Footer from '../../Components/Footer/Footer';
import { Spin } from 'antd';
import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { AppContext } from '../../utils/context';

const Files = (props) => {
  const id = props.match.params.id;
  const dispatch = useDispatch();
  const files = useSelector((state) => state.folders.files);
  const folders = useSelector((state) => state.folders.folders);
  const signal_files = useSelector((state) => state.signal.files);
  const filesId = useSelector((state) => state.signal.file_id);
  const loading = useSelector((state) => state.folders.loading);
  const [show, setShow] = useState(false);
  const { setEmailSender, emailSender } = useContext(AppContext);

  useEffect(() => {
    dispatch(getFolders());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFileByFolder(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (id === filesId) dispatch(getFolders());
  }, [dispatch, filesId, id]);

  useEffect(() => {
    if (signal_files && id === filesId) dispatch(getFileByFolder(id));
  }, [dispatch, id, signal_files, filesId]);

  const fileName = useMemo(() => {
    return folders.filter((folder) => folder.id === id)[0]?.name || '';
  }, [id, folders]);

  const cards = useMemo(() => {
    return files.length ? (
      <>
        {files.map((file, index) => (
          <Card
            file={file}
            type
            path="pdf"
            id={file.id}
            key={file.id + index}
            url={file.thumbnailBase64 || file.localThumbnail || file.thumbnail || file.mobile_thumbnail}
            title={file.name}
            date={file.date_published}
            pdfUrl={file.localPathBase64 || file.path}
            localUrl={file.thumbnailBase64 || files?.[index]?.thumbnailBase64 || files?.[index]?.localUrl || file?.localUrl}
            starsDb={`file-stars-${id}`}
            fileId={id}
            download={file}
            description={file.subject}
            localUrlBase64={file.localUrlBase64}
          />
        ))}
      </>
    ) : null;
  }, [files, id]);

  return (
    <div className="content">
      <div className="main-block">
        <div
          className={show && !emailSender ? 'nav-top-active' : 'nav-top'}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <div className="nav-top__item" onClick={() => setEmailSender(true)}>
            <MailOutlined className="nav__item-icon" />
            <p>Email</p>
          </div>
        </div>
        <h1>
          <Link to={'/files'}>
            <ArrowLeftOutlined className="back-icon" />
          </Link>
          Files / {fileName}
        </h1>
        <div className="main-block__content">{cards}</div>
      </div>
      {loading && (
        <div className="main-block__spinner-container">
          <Spin spinning className="spinner" size="large" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default memo(Files);
