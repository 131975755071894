import React from 'react';
import { Modal } from 'antd';

const Warning = ({ warning, setWarning }) => {
  return (
    <Modal visible={warning} onCancel={() => setWarning(false)} onOk={()=>setWarning(false)} footer={null}>
      <p>Item is not yet downloaded</p>
    </Modal>
  );
};

export default Warning;
