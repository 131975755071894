import React, { memo, useEffect } from 'react';
import Card from '../../Components/Card.js/Card';
import Footer from '../../Components/Footer/Footer';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPresentations } from '../../store/actions/presentationActions';

const Presentations = () => {
  const dispatch = useDispatch();
  const presentations = useSelector((state) => state.presentation.presentations);
  const signal = useSelector((state) => state.signal.presentations);
  const loading = useSelector((state) => state.presentation.loading);

  useEffect(() => {
    dispatch(getAllPresentations('presentations-stars'));
  }, [dispatch]);

  useEffect(() => {
    if (signal) {
      dispatch(getAllPresentations('presentations-stars'));
    }
  }, [dispatch, signal]);

  return (
    <div className="content">
      <div className="main-block">
        <h1>Presentations</h1>
        <div className="main-block__content">
          {presentations.length
            ? presentations
                .filter((item) => !item.data.is_video && !item?.e_detailer)
                .map((presentation, index) => (
                  <Card
                    localUrlBase64={presentation.data.localUrlBase64}
                    description={presentation.data.subject}
                    localUrl={presentation.data.localUrl}
                    path="presentations"
                    id={presentation.data.id}
                    key={presentation.data.id + index}
                    url={presentation.data.mobile_thumbnail}
                    title={presentation.data.name}
                    date={presentation.data.date_published}
                    slides={presentation.slides}
                    starsDb={'presentations-stars'}
                    download={presentation.slides}
                  />
                ))
            : null}
        </div>
      </div>
      {loading && (
        <div className="main-block__spinner-container">
          <Spin spinning className="spinner" size="large" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default memo(Presentations);
