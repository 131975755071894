import { GET_ALL_FAILURE, GET_ALL_REQUEST, GET_ALL_SUCCESS, LOGOUT_USER } from '../actionTypes';

export const initialState = {
  files: [],
  loading: false,
  error: null,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REQUEST:
      return { ...state, loading: true, error: null, files: action.files };
    case GET_ALL_SUCCESS:
      return { ...state, loading: false, files: action.files, error: null };
    case GET_ALL_FAILURE:
      return { ...state, loading: false, files: action.files, error: action.error };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default searchReducer;
