import {
  CLINICAL_FILES,
  CLINICAL_FOLDERS,
  LOGOUT_USER,
  REFRESH_FILES_REQUEST,
  REFRESH_FILES_SUCCESS,
  REFRESH_FOLDERS,
  REFRESH_PRESENTATIONS,
} from '../actionTypes';

const initialState = {
  presentations: false,
  folders: false,
  files: false,
  clinicalFolder: false,
  clinicalFiles: false,
  file_id: null,
};

const signalReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_PRESENTATIONS:
      return { ...state, presentations: !state.presentations };
    case REFRESH_FOLDERS:
      return { ...state, folders: !state.folders };
    case REFRESH_FILES_REQUEST:
      return { ...state, files: true, file_id: action.id };
    case REFRESH_FILES_SUCCESS:
      return { ...state, files: false, file_id: null };
    case CLINICAL_FOLDERS:
      return { ...state, clinicalFolder: !state.clinicalFolder };
    case CLINICAL_FILES:
      return { ...state, clinicalFiles: !state.clinicalFiles };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default signalReducer;
