import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../store/actions/authActions';
import AuthField from '../../Components/AuthField/AuthField';
import { Link } from 'react-router-dom';
import './styles.scss';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [username, setUserName] = useState('');

  const submitHandler = () => {
    dispatch(forgotPassword(username));
  };

  return (
    <div className="forgot-password auth">
      <div className="auth__body">
        <AuthField type="email" value={username} setValue={setUserName} placeholder="Enter email" />
        <div className="auth-buttons">
          <Link to="/" className="auth__button-cancel">
            Cancel
          </Link>
          <button className="auth__button" onClick={submitHandler}>
            Submit
          </button>
        </div>
      </div>

      <div className="auth__footer"></div>
    </div>
  );
};

export default ForgotPassword;
