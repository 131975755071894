import axiosApi from '../../utils/axios';
import {
  GET_AUDIENCE_FAILURE,
  GET_AUDIENCE_SUCCESS,
  GET_CUSTOM_SUCCESS,
  GET_PRESENTATION_SUCCESS,
  GET_PRESENTATIONS_FAILURE,
  GET_PRESENTATIONS_REQUEST,
  GET_PRESENTATIONS_SUCCESS,
} from '../actionTypes';
import { compareNumbers, setHandler, starsRatingLocal } from '../../utils/functions';
import { get } from '../../utils/idb-config';
import { controller } from '../../utils/axiosController';

const getPresentationsRequest = () => ({ type: GET_PRESENTATIONS_REQUEST });
const getPresentationsSuccess = (presentations) => ({ type: GET_PRESENTATIONS_SUCCESS, presentations });
const getPresentationsFailure = (error, presentations) => ({ type: GET_PRESENTATIONS_FAILURE, error, presentations });

export const getPresentationSuccess = (presentation, e_detailer) => ({ type: GET_PRESENTATION_SUCCESS, presentation, e_detailer });

const getAudienceSuccess = (audiences) => ({ type: GET_AUDIENCE_SUCCESS, audiences });
const getAudienceFailure = (error, audiences) => ({ type: GET_AUDIENCE_FAILURE, error, audiences });

const getCustomSuccess = (custom) => ({ type: GET_CUSTOM_SUCCESS, custom });

export const getAllPresentations = (db) => {
  return async (dispatch, getState) => {
    const token = getState().auth.user.token;
    const stars = (await get(db)) || [];
    try {
      dispatch(getPresentationsRequest());
      const presentations = (await get('presentations')) || [];
      if (presentations.length) dispatch(getPresentationsSuccess(starsRatingLocal(presentations, stars).sort(compareNumbers)));
      else {
        const response = await axiosApi.get(`/presentations/fetchUpdated?token=${token}`, { signal: controller.signal });
        dispatch(getPresentationsSuccess(starsRatingLocal(response.data.body.presentations, stars).sort(compareNumbers)));
      }
    } catch (error) {
      const presentations = await get('presentations');
      dispatch(getPresentationsFailure(error.response.data, starsRatingLocal(presentations).sort(compareNumbers)));
    }
  };
};

export const getAudienceTypes = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.user.token;
    try {
      const response = await axiosApi.get(`presentations/getAudienceTypes?token=${token}`, { signal: controller.signal });
      dispatch(getAudienceSuccess(response.data.body.audience_types || []));
      await setHandler('audiences', response.data.body.audience_types || []);
    } catch (error) {
      const response = await get('audiences');
      dispatch(getAudienceFailure(error.response.data, response || []));
    }
  };
};

export const getCustom = (setLoading) => {
  setLoading(true);
  return async (dispatch) => {
    const stars = (await get('custom-stars')) || [];
    const response = (await get('custom')) || [];
    dispatch(getCustomSuccess(starsRatingLocal(response, stars).sort(compareNumbers)));
    setLoading(false);
  };
};
