import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './styles.scss';

const AuthField = ({ error, type, name, label, value, setValue, placeholder, onKeyDown }) => {
  return (
    <label className="auth-field__label">
      {label ? <span className="auth-field__label-content"> {label}</span> : null}
      <input
        name={name}
        onKeyDown={(e) => onKeyDown(e)}
        type={type}
        className={`auth-field ${error ? 'auth-field-error' : ''}`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
      />
      {type === 'password' ? <LockOutlined className="auth-field--icon" /> : <UserOutlined className="auth-field--icon" />}
    </label>
  );
};

export default AuthField;
