import React, { useEffect, useMemo, useRef, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import Slide from '../../Components/Slide/Slide';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useSwipeable } from 'react-swipeable';
import { postReportPresentation } from '../../store/actions/reportActions';
import { get } from '../../utils/idb-config';
import { useHistory } from 'react-router-dom';
import EDetailerNavigationTop from '../../Components/Navigation/EDetailerNavigationTop';
import EDetailerNavigationBottom from '../../Components/Navigation/EDetailerNavigationBottom';
import EList from '../../Components/List/EList';
import './styles.scss';

const EdetailerPresentation = (props) => {
  const pathname = useHistory().location.pathname;
  const id = props.match.params.id;
  const dispatch = useDispatch();
  const swiperRef = useRef();
  const slides = useSelector((state) => state.presentation.presentation);
  const e_detailer = useSelector((state) => state.presentation.e_detailer);
  const loading = useSelector((state) => state.presentation.loading);
  const [state, setState] = useState(0);
  const startDate = Date.now();
  const [quantity, setQuantity] = useState([]);
  const [customSlides, setCustomSlides] = useState([]);
  const [list, setList] = useState(false);
  const [customEDetailer, setCustomEDetailer] = useState({});
  const [notes, setNotes] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(
        postReportPresentation({
          id,
          startDate,
          endDate: Date.now(),
          quantity: quantity.length,
          audience: '',
          custom: pathname.includes('custom'),
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const effect = async () => {
      const response = (await get('presentations')) || [];
      const responseFilter = response.filter((item) => item?.data.id === id);
      if (responseFilter.length) {
        setCustomSlides(responseFilter[0]?.slides.map((item, index) => ({ ...item, index })) || []);
        setCustomEDetailer(responseFilter[0]);
      } else {
        setCustomSlides(slides.map((item, index) => ({ ...item, index })) || []);
        setCustomEDetailer(e_detailer || {});
      }
    };

    effect();
  }, [slides, id, e_detailer]);

  useEffect(() => {
    document.querySelector('.slide-show').focus();
  }, []);

  const handlers = useSwipeable({
    onSwipedRight: () => prevSlideHandler(),
    onSwipedLeft: () => nextSlideHandler(),
    trackMouse: true,
    trackTouch: true,
    touchEventOptions: { passive: true },
    preventDefaultTouchmoveEvent: true,
    delta: 5,
  });

  const nextSlideHandler = () => {
    setState((prev) => (prev + 1 < customSlides.length ? prev + 1 : prev));
  };

  const prevSlideHandler = () => {
    setState((prev) => (prev - 1 > 0 ? prev - 1 : 0));
  };

  const SlidesMemo = useMemo(() => {
    const refHandlers = (el) => {
      handlers.ref(el);
      swiperRef.current = el;
    };

    const keyHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.key === 'ArrowRight') setState((prev) => (prev + 1 < customSlides.length ? prev + 1 : prev));
      else if (event.key === 'ArrowLeft') setState((prev) => (prev - 1 > 0 ? prev - 1 : 0));
    };

    return (
      <div
        className="slide-show"
        tabIndex="0"
        style={{ touchAction: 'pan-y' }}
        onKeyDown={keyHandler}
        {...handlers}
        ref={refHandlers}
        draggable={false}
      >
        {customSlides.length ? (
          customSlides.map((slide, index) => (
            <Slide
              edetailer
              id={id}
              setQuantity={setQuantity}
              notesHtml={slide.notes}
              type={slide.type}
              url={slide.url}
              videoUrl={slide.video_url || slide.url}
              previewUrl={slide.preview_url}
              key={slide.url}
              index={index}
              state={state}
              setState={setState}
              localUrl={slide.localUrl}
              notes={notes}
              localUrlBase64={slide.localUrlBase64}
            />
          ))
        ) : (
          <div className="spinner-container">
            <Spin spinning className="spinner" size="large" />
          </div>
        )}
        <LeftOutlined
          draggable={false}
          className="slide-button prev-slide"
          onClick={() => setState((prev) => (prev - 1 > 0 ? prev - 1 : 0))}
        />
        <RightOutlined
          draggable={false}
          className="slide-button next-slide"
          onClick={() => setState((prev) => (prev + 1 < customSlides.length ? prev + 1 : prev))}
        />
      </div>
    );
  }, [id, customSlides, handlers, state, notes]);

  return (
    <section className="presentation-page">
      {!loading ? (
        <div>
          <EDetailerNavigationTop e_detailer={customEDetailer?.e_detailer} state={state} setState={setState} slides={customSlides} />
          {SlidesMemo}
          <EDetailerNavigationBottom
            setNotes={setNotes}
            setList={setList}
            state={state}
            list={list}
            slides={customSlides}
            e_detailer={customEDetailer?.e_detailer}
            setState={setState}
            logo={customEDetailer?.e_detailer?.logo}
            localLogo={customEDetailer?.e_detailer?.localLogo}
            home_slide_id={customEDetailer?.e_detailer?.home_slide_id}
          />
        </div>
      ) : (
        <div className="spinner-container">
          <Spin spinning={loading} className="spinner" size="large" />
        </div>
      )}
      {list && <EList slides={customSlides} setList={setList} setState={setState} />}
    </section>
  );
};

export default memo(EdetailerPresentation);
