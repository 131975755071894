import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import Slide from '../../Components/Slide/Slide';
import NavigationTop from '../../Components/Navigation/NavigationTop';
import NavigationBottom from '../../Components/Navigation/NavigationBottom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useSwipeable } from 'react-swipeable';
import { postReportPresentation } from '../../store/actions/reportActions';
import { get } from '../../utils/idb-config';
import { useHistory } from 'react-router-dom';
import history from '../../utils/history';
import './styles.scss';

const Presentation = (props) => {
  const pathname = useHistory().location.pathname;
  const id = props.match.params.id;
  const dispatch = useDispatch();
  const swiperRef = useRef();
  const slides = useSelector((state) => state.presentation.presentation);
  const loading = useSelector((state) => state.presentation.loading);
  const [state, setState] = useState(0);
  const [topNavigation, setTopNavigation] = useState(false);
  const [bottomNavigation, setBottomNavigation] = useState(false);
  const [notes, setNotes] = useState(false);
  const startDate = Date.now();
  const [quantity, setQuantity] = useState([]);
  const [customSlides, setCustomSlides] = useState([]);
  const [audience, setAudience] = useState('');

  useEffect(() => {
    return () => {
      dispatch(
        postReportPresentation({
          id,
          startDate,
          endDate: Date.now(),
          quantity: quantity.length,
          audience,
          custom: pathname.includes('custom'),
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const effect = async () => {
      const response = (await get('presentations')) || [];
      const responseFilter = response.filter((item) => item?.data.id === id);
      if (responseFilter.length) setCustomSlides(responseFilter[0]?.slides || []);
      else setCustomSlides(slides || []);
    };

    effect();
  }, [slides, id]);

  useEffect(() => {
    document.querySelector('.slide-show').focus();
  }, []);

  const handlers = useSwipeable({
    onSwipedRight: () => prevSlideHandler(),
    onSwipedLeft: () => nextSlideHandler(),
    onSwipedUp: () => setBottomNavigation((prev) => !prev),
    onSwipedDown: () => setTopNavigation((prev) => !prev),
    trackMouse: true,
    trackTouch: true,
    touchEventOptions: { passive: true },
    preventDefaultTouchmoveEvent: true,
    delta: 5,
  });

  const nextSlideHandler = () => {
    setState((prev) => (prev + 1 < customSlides.length ? prev + 1 : prev));
  };

  const prevSlideHandler = () => {
    setState((prev) => (prev - 1 > 0 ? prev - 1 : 0));
  };

  const SlidesMemo = useMemo(() => {
    const refHandlers = (el) => {
      handlers.ref(el);
      swiperRef.current = el;
    };

    const keyHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.key === 'ArrowRight') setState((prev) => (prev + 1 < customSlides.length ? prev + 1 : prev));
      else if (event.key === 'ArrowLeft') setState((prev) => (prev - 1 > 0 ? prev - 1 : 0));
      else if (event.key === 'ArrowDown') setBottomNavigation((prev) => !prev);
      else if (event.key === 'ArrowUp') setTopNavigation((prev) => !prev);
      else if (event.key === 'Enter' && topNavigation) history.push('/presentations');
    };

    return (
      <div className="slide-show" tabIndex="0" style={{ touchAction: 'pan-y' }} onKeyDown={keyHandler} {...handlers} ref={refHandlers}>
        {customSlides.length ? (
          customSlides.map((slide, index) => (
            <Slide
              id={id}
              setQuantity={setQuantity}
              notesHtml={slide.notes}
              type={slide.type}
              url={slide.url}
              videoUrl={slide.video_url || slide.url}
              previewUrl={slide.preview_url}
              key={slide.url}
              index={index}
              notes={notes}
              state={state}
              setState={setState}
              localUrl={slide.localUrl}
              localUrlBase64={slide.localUrlBase64}
            />
          ))
        ) : (
          <div className="spinner-container">
            <Spin spinning className="spinner" size="large" />
          </div>
        )}
        <LeftOutlined
          draggable={false}
          className="slide-button prev-slide"
          onClick={() => setState((prev) => (prev - 1 > 0 ? prev - 1 : 0))}
        />
        <RightOutlined
          draggable={false}
          className="slide-button next-slide"
          onClick={() => setState((prev) => (prev + 1 < customSlides.length ? prev + 1 : prev))}
        />
      </div>
    );
  }, [id, customSlides, handlers, notes, state, topNavigation]);

  return (
    <section className="presentation-page">
      {!loading ? (
        <>
          <NavigationTop
            slides={customSlides}
            id={id}
            show={topNavigation}
            setShow={setTopNavigation}
            setState={setState}
            notes={notes}
            setNotes={setNotes}
            setAudienceReport={setAudience}
            setSlides={setCustomSlides}
          />
          {SlidesMemo}
          <NavigationBottom
            state={state}
            notes={notes}
            show={notes || bottomNavigation}
            setShow={setBottomNavigation}
            slides={customSlides}
            setState={setState}
          />
        </>
      ) : (
        <div className="spinner-container">
          <Spin spinning={loading} className="spinner" size="large" />
        </div>
      )}
    </section>
  );
};

export default memo(Presentation);
