import React, {useEffect, useState} from 'react';
import {loadFromLocalStorage, saveToLocalStorage} from './utils/localStorage';

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
    setSupportsPWA(false);
  };

  const cancelHandler = () => {
    setSupportsPWA(false);
    saveToLocalStorage('pwa', true);
  };

  if (!supportsPWA || loadFromLocalStorage('pwa')) {
    return null;
  }

  return (
    <div className="pwa-modal">
      <h3>Install iPresent App</h3>
      <button className="pwa-cancel" onClick={cancelHandler}>
        Cancel
      </button>
      <button className="pwa-install" id="setup_button" aria-label="Install app" title="Install app" onClick={onClick}>
        Install
      </button>
    </div>
  );
};

export default InstallPWA;
