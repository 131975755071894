import React, { useContext, useEffect, useState, memo } from 'react';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';
import { setHandler, sliceString, updateObjectHandler } from '../../utils/functions';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import Folder from '../../assets/folder.png';
import { useDispatch } from 'react-redux';
import { getAllPresentations, getCustom, getPresentationSuccess } from '../../store/actions/presentationActions';
import { getClinicalPapers, getFileByFolder, getFolders, viewPdf } from '../../store/actions/foldersActions';
import Image from '../Image/Image';
import { Rate } from 'antd';
import { get } from '../../utils/idb-config';
import history from '../../utils/history';
import Warning from '../Warning/Warning';
import { AppContext } from '../../utils/context';
import './styles.scss';

const Card = ({
  file = {},
  localUrl,
  type = true,
  to = null,
  id,
  path,
  url,
  date,
  title,
  description = 'This is the sub-title for the presentation',
  slides,
  folder = false,
  starsDb,
  setLoading,
  fileId,
  effect,
  download,
  e_detailer = {},
  localUrlBase64,
}) => {
  const dispatch = useDispatch();
  const [starsState, setStars] = useState(0);
  const [state, setState] = useState(false);
  const [warning, setWarning] = useState(false);
  const width = useResizeWindows();
  const { setEmailSender } = useContext(AppContext);

  useEffect(() => {
    const effect = async () => {
      const array = (await get(starsDb)) || [];
      const filterArray = array.filter((item) => item.id === id);
      setStars(filterArray[0]?.value || '0');
    };

    effect();
  }, [starsDb, id]);

  const notDownload = () => {
    const fileBlob = Array.isArray(download) ? download.filter((item) => item.localUrl) : !!download.localUrl;
    if (Array.isArray(download) && download.length !== fileBlob.length) {
      setWarning(true);
      return false;
    } else if (!download) {
      setWarning(true);
      return false;
    } else {
      return true;
    }
  };

  const navigationHandler = () => {
    if (path === 'pdf') dispatch(viewPdf(file));
    else if (path === 'e-detailer') dispatch(getPresentationSuccess(slides, e_detailer));
    else dispatch(getPresentationSuccess(slides));
    const response = notDownload();
    if (response) history.push(to || `/${path}/view/${id}`);
    setEmailSender(false);
  };

  const changeStarsHandler = async (value) => {
    const array = (await get(starsDb)) || [];
    const filterArray = array.filter((item) => item.id === id);

    if (filterArray.length) {
      const cards = array.map((item) => {
        if (item.id === id) {
          item.value = value;
          return item;
        } else return item;
      });

      await setHandler(starsDb, cards);
    } else {
      await updateObjectHandler(starsDb, { id, value });
    }

    setStars(value);

    if (starsDb === 'presentations-stars' || starsDb === 'videos-stars' || starsDb === 'e-detailer-stars')
      dispatch(getAllPresentations(starsDb));
    else if (starsDb === 'clinical-papers-stars') dispatch(getClinicalPapers());
    else if (starsDb === 'folders-stars') dispatch(getFolders());
    else if (starsDb === 'custom-stars') dispatch(getCustom(setLoading));
    else if (starsDb === `file-stars-${fileId}`) dispatch(getFileByFolder(fileId));
    else if (starsDb === `clinical-file-stars-${fileId}`) effect();
  };

  return (
    <>
      <div
        className={`card ${state ? `card__border ${!type ? 'card__border-none' : ''}` : ''}`}
        onMouseEnter={() => setState(true)}
        onMouseLeave={() => setState(false)}
      >
        <div onClick={navigationHandler}>
          <div className="card__img">
            <Image
              src={folder ? Folder : localUrlBase64 ? localUrlBase64 : localUrl ? URL.createObjectURL(localUrl) : url}
              localUrl={localUrlBase64 ? localUrlBase64 : localUrl ? URL.createObjectURL(localUrl) : !type && Folder}
              alt="card-img"
            />
          </div>
          <h4 className={`card__title ${state ? 'card__title-border' : ''} ${!localUrl && !localUrlBase64 ? 'card-disabled' : ''}`}>
            {sliceString(width, title, 47, 42, 40)}
          </h4>
          <div className={`card__date  ${state ? `card__date-border ${!type ? 'card__date-border-none' : ''}` : ''}`}>
            <div className={`card__date-item`}>
              <p>{moment(date || new Date()).format('D MMMM YYYY')}</p>
              <CalendarOutlined className="date-icon" />
            </div>
            <div className={`card__date-item`}>
              <CalendarOutlined className="date-icon" />
            </div>
          </div>
          <h4 className={`card__title ${state ? 'card__title-border' : ''} ${!localUrl && !localUrlBase64 ? 'card-disabled' : ''}`}>
            {description}
          </h4>
        </div>
        <div className={`card__stars ${state ? 'card__stars-border' : ''}`}>
          <Rate onChange={changeStarsHandler} value={starsState} />
        </div>
      </div>
      {warning && <Warning warning={warning} setWarning={setWarning} />}
    </>
  );
};

export default memo(Card);
