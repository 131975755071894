import React, {useState} from 'react';
import {ExportOutlined, FormOutlined, HomeOutlined, ProfileOutlined, UnorderedListOutlined} from '@ant-design/icons';
import history from '../../utils/history';
import References from '../References/References';
import {imgURL} from '../../utils/config';
import './styles.scss';

const EDetailerNavigationBottom = ({ logo, setList, setState, slides, home_slide_id = null, setNotes, state, localLogo,e_detailer = [] }) => {
  const [references, setReferences] = useState(false);

  const links = [
    {
      id: 1,
      icon: <HomeOutlined className="nav__item-icon" style={{color: e_detailer?.colors?.color_footer_words ?? '#fff'}}/>,
      onClick: () => {
        if (home_slide_id) {
          const index = slides.filter((item) => item.id === home_slide_id)[0].index;
          setState(index);
        } else setState(0);
      },
    },
    {
      id: 2,
      icon: <UnorderedListOutlined className="nav__item-icon" style={{color: e_detailer?.colors?.color_footer_words ?? '#fff'}}/>,
      onClick: () => {
        setList((prev) => !prev);
      },
    },
    {
      id: 3,
      icon: <ProfileOutlined className="nav__item-icon" style={{color: e_detailer?.colors?.color_footer_words ?? '#fff'}}/>,
      onClick: () => {
        setReferences(true);
      },
    },
    {
      id: 4,
      icon: <FormOutlined className="nav__item-icon" style={{color: e_detailer?.colors?.color_footer_words ?? '#fff'}}/>,
      onClick: () => {
        setNotes((prev) => !prev);
      },
    },
    {
      id: 5,
      icon: <ExportOutlined className="nav__item-icon" style={{color: e_detailer?.colors?.color_footer_words ?? '#fff'}}/>,
      onClick: () => {
        history.push('/e-detailer');
      },
    },
  ];
  return (
    <nav className="e-detailer-nav" style={{backgroundColor: e_detailer?.colors?.color_footer_background ?? '#185B97'}}>
      <div className="e-detailer-nav__inner">
        {links.map((item) => (
          <div onClick={item.onClick} key={item.id} className="e-detailer-nav__item">
            {item.icon}
          </div>
        ))}
      </div>

      {(localLogo || logo) &&
        <img src={localLogo ? URL.createObjectURL(localLogo) : imgURL + logo} width={80} height={40} alt="log"
            onError={({currentTarget}) => {
              currentTarget.onerror = null;
              currentTarget.style = "display: none!important";
            }}
      />}

      {references && <References visible={references} setReferences={setReferences} slides={slides} currentSlide={state} />}
    </nav>
  );
};

export default EDetailerNavigationBottom;
