import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { imgURL } from '../../utils/config';
import Image from '../Image/Image';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { get } from '../../utils/idb-config';
import { setHandler } from '../../utils/functions';

const Slide = ({ id, localUrl, url, index, state, type, notes, notesHtml, setQuantity, previewUrl, videoUrl, localUrlBase64 }) => {
  const ref = useRef(null);
  const videoRef = useRef(null);
  const [seconds, setSeconds] = useState(0);

  const isVideo = useMemo(() => {
    return (
      videoUrl.includes('mp4') ||
      videoUrl.includes('mov') ||
      videoUrl.includes('avi') ||
      videoUrl.includes('avhcd') ||
      videoUrl.includes('webm') ||
      videoUrl.includes('mpeg') ||
      videoUrl.includes('flv')
    );
  }, [videoUrl]);

  const width = useResizeWindows();
  const [controls, setControls] = useState(false);

  useEffect(() => {
    let interval;

    if (index === state) {
      interval = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [index, state]);

  useEffect(() => {
    const effect = async () => {
      const order = (await get(`order-${id}`)) || [];
      const array = [...order];

      const prevFilter = array.filter((item) => item.index === index);
      if (!array.length || !prevFilter.length) array.push({ index, seconds });
      else {
        array.forEach((item) => {
          if (item.index === index) item.seconds = seconds;
          return item;
        });
      }

      await setHandler(`order-${id}`, array);
    };

    effect();
  }, [seconds, index, id]);

  useEffect(() => {
    const play = async () => {
      await videoRef.current.play();
      videoRef.current.controls = controls;
    };

    const pause = async () => {
      await videoRef.current.pause();
    };

    if (index === state) {
      if (navigator?.userAgentData?.mobile || width < 1280) {
        ref.current?.scrollIntoViewIfNeeded(true);
      } else ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      setQuantity((prev) => (prev.includes(index) ? prev : [...prev, index]));
      isVideo && play().then((r) => r);
    } else isVideo && pause().then((r) => r);
  }, [index, state, setQuantity, width, controls, isVideo]);

  useEffect(() => {
    if (!controls) document.querySelector('.slide-show').focus();
  }, [controls]);

  const controllerHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setControls(!controls);
  };

  return (
    <>
      {!notes ? (
        <>
          {type === 'video' ? (
            <div className="slide" ref={ref} onClick={controllerHandler}>
              <video ref={videoRef} autoPlay width={width} height={'100%'} style={{ paddingBottom: '15px' }}>
                <source src={localUrlBase64 ? localUrlBase64 : localUrl ? URL.createObjectURL(localUrl) : imgURL + videoUrl} />
              </video>
            </div>
          ) : type === 'pdf' ? (
            <img
              src={imgURL + previewUrl}
              alt="slide"
              className="slide"
              ref={ref}
              draggable={false}
              onError={(e) => (e.target.src = localUrlBase64 ? localUrlBase64 : localUrl ? URL.createObjectURL(localUrl) : null)}
            />
          ) : (
            <img
              src={localUrlBase64 ? localUrlBase64 : localUrl ? URL.createObjectURL(localUrl) : imgURL + previewUrl}
              alt="slide"
              className="slide"
              onError={(e) => (e.target.src = localUrlBase64 ? localUrlBase64 : localUrl ? URL.createObjectURL(localUrl) : null)}
              ref={ref}
              draggable={false}
            />
          )}
        </>
      ) : (
        <div className="slide" ref={ref} draggable={false}>
          <div className="slide-notes">
            {type === 'video' ? (
              <div className="video">
                <video ref={videoRef} autoPlay={index === state} width={'100%'} height={'100%'} draggable={false}>
                  <source src={localUrlBase64 ? localUrlBase64 : localUrl ? URL.createObjectURL(localUrl) : imgURL + videoUrl} />
                </video>
              </div>
            ) : (
              <Image
                src={type === 'pdf' ? previewUrl : url}
                localUrl={localUrlBase64 ? localUrlBase64 : localUrl ? URL.createObjectURL(localUrl) : null}
              />
            )}
            <div className="notes">
              <p>Slide {index + 1}.</p>
              {parse(notesHtml || '')}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(Slide);
