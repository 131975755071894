import { applyMiddleware, combineReducers, createStore } from 'redux';
import { loadFromLocalStorage, saveToLocalStorage } from '../utils/localStorage';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './reducers/authReducer';
import presentationReducer from './reducers/presentationReducer';
import folderReducer from './reducers/folderReducer';
import axiosApi from '../utils/axios';
import { logout } from './actions/authActions';
import searchReducer from './reducers/searchReducer';
import senderReducer from './reducers/senderReducer';
import signalReducer from './reducers/signalReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  presentation: presentationReducer,
  folders: folderReducer,
  search: searchReducer,
  sender: senderReducer,
  signal: signalReducer,
});

const preloadedState = loadFromLocalStorage('auth') || {};
const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));

store.subscribe(() => {
  saveToLocalStorage('auth', {
    auth: store.getState()?.auth,
  });
});

axiosApi.interceptors.response.use((config) => {
  if (config.data.status === 'InvalidToken') store.dispatch(logout());
  else return config;
});

export default store;
