export const viewPort = () => {
  window.addEventListener('resize', applyViewPort);
  window.addEventListener('orientationchange', () => applyViewPort());
  userAgent();
  applyViewPort();
};

const applyViewPort = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  const windowWidth = window.screen.width;

  if (windowWidth > 600) {
    const viewMetaTag = document.querySelector('[name="viewport"]');
    viewMetaTag.setAttribute('content', `width=device-width , initial-scale=1`);
  }
};

const userAgent = () => {
  if (/Android|webOS|Iphone|Ipad|Ipod|BlackBerry/i.test(navigator.userAgent)) {
    const windowWidth = window.screen.width;
    if (windowWidth < 600) {
      // const viewMetaTag = document.querySelector('[name="viewport"]');
      // viewMetaTag.setAttribute('content', `width=device-width , initial-scale=0.4`);
    }
  }
};
