import React, { useEffect, useState } from 'react';
import Image from '../Image/Image';

const Slide = ({ slide, allChecked, setPresentationsSave }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (allChecked) {
      setChecked(true);
      setPresentationsSave((prev) => {
        const currentFilter = prev.filter((item) => item.id === slide.id);
        if (currentFilter.length) return prev;
        else return [...prev, slide];
      });
    } else if (!allChecked) {
      if (slide.is_mandatory) {
        setChecked(true);

        setPresentationsSave((prev) => {
          const currentFilter = prev.filter((item) => item.id === slide.id);
          if (currentFilter.length) return prev;
          else return [...prev, slide];
        });
      } else {
        setChecked(false);
        setPresentationsSave((prev) => prev.filter((item) => item.url !== slide.url));
      }
    }
  }, [allChecked, slide, setPresentationsSave]);

  return (
    <li className="presentation-customize-list-item">
      <Image
        src={slide.type === 'video' || slide.type === 'pdf' ? slide?.preview_url : slide.localUrlBase64 || slide.url}
        localUrl={
          slide.localPreview ? URL.createObjectURL(slide.localPreview) : slide.localUrl ? URL.createObjectURL(slide.localUrl) : null
        }
      />
      <p>{slide.title}</p>
      <input
        type="checkbox"
        checked={slide.is_mandatory || checked}
        disabled={slide.is_mandatory}
        onChange={(e) => {
          setChecked(e.target.checked);
          if (e.target.checked) setPresentationsSave((prev) => [...prev, slide]);
          else setPresentationsSave((prev) => prev.filter((item) => item.url !== slide.url));
        }}
      />
    </li>
  );
};

export default Slide;
