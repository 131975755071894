import React, { memo, useContext, useEffect, useState } from 'react';
import Footer from '../../Components/Footer/Footer';
import Card from '../../Components/Card.js/Card';
import { Spin } from 'antd';
import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFolders } from '../../store/actions/foldersActions';
import { AppContext } from '../../utils/context';

const Folders = () => {
  const dispatch = useDispatch();
  const folders = useSelector((state) => state.folders.folders);
  const signal = useSelector((state) => state.signal.folders);
  const loading = useSelector((state) => state.folders.loading);
  const [show, setShow] = useState(false);
  const { setEmailSender, emailSender } = useContext(AppContext);

  useEffect(() => {
    dispatch(getFolders());
  }, [dispatch]);

  useEffect(() => {
    if (signal) dispatch(getFolders());
  }, [dispatch, signal]);

  return (
    <div className="content">
      <div className="main-block">
        <div
          className={show && !emailSender ? 'nav-top-active' : 'nav-top'}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <div className="nav-top__item" onClick={() => setEmailSender(true)}>
            <MailOutlined className="nav__item-icon" />
            <p>Email</p>
          </div>
        </div>
        <h1>
          <Link to={'/presentations'}>
            <ArrowLeftOutlined className="back-icon" />
          </Link>
          Files
        </h1>
        <div className="main-block__content">
          {folders.length
            ? folders.map((folder, index) => (
                <Card
                  type={false}
                  path="files"
                  id={folder.id}
                  key={folder.id + index}
                  url={folder.thumbnail}
                  title={folder.name}
                  date={folder.date_published}
                  folder
                  starsDb="folders-stars"
                  download={{ localUrl: true }}
                  description={folder.subject}
                  localUrl={folder.localUrl}
                  localUrlBase64={folder.localUrlBase64}
                />
              ))
            : null}
        </div>
      </div>
      {loading && (
        <div className="main-block__spinner-container">
          <Spin spinning className="spinner" size="large" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default memo(Folders);
