import {
  LOGOUT_USER,
  POST_EMAIL_SENDER_FAILURE,
  POST_EMAIL_SENDER_REQUEST,
  POST_EMAIL_SENDER_SUCCESS,
} from '../actionTypes';

export const initialState = {
  loading: false,
  error: null,
};

const senderReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_EMAIL_SENDER_REQUEST:
      return { ...state, loading: true, error: null };
    case POST_EMAIL_SENDER_SUCCESS:
      return { ...state, loading: false, error: null };
    case POST_EMAIL_SENDER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default senderReducer;
