import {
  POST_REPORT_FILE_FAILURE,
  POST_REPORT_FILE_REQUEST,
  POST_REPORT_FILE_SUCCESS,
  POST_REPORT_PRESENTATION_FAILURE,
  POST_REPORT_PRESENTATION_REQUEST,
  POST_REPORT_PRESENTATION_SUCCESS,
} from '../actionTypes';
import { setHandler, updateObjectHandler } from '../../utils/functions';
import { get } from '../../utils/idb-config';
import { apiURL } from '../../utils/config';

const postReportFileRequest = () => ({ type: POST_REPORT_FILE_REQUEST });
const postReportFileSuccess = () => ({ type: POST_REPORT_FILE_SUCCESS });
const postReportFileFailure = () => ({ type: POST_REPORT_FILE_FAILURE });

const postReportPresentationRequest = () => ({ type: POST_REPORT_PRESENTATION_REQUEST });
const postReportPresentationSuccess = () => ({ type: POST_REPORT_PRESENTATION_SUCCESS });
const postReportPresentationFailure = () => ({ type: POST_REPORT_PRESENTATION_FAILURE });

export const postReportPresentation = (presentation) => {
  return async (dispatch, getState) => {
    const token = getState().auth.user.token;
    const order = (await get(`order-${presentation.id}`)) || [];
    try {
      if (!order.length) return;
      let str = '';

      if (order.length) {
        order.forEach((item) => {
          if (item.seconds) str += `${item.index + 1}/${item.seconds}/`;
        });
      }

      dispatch(postReportPresentationRequest());
      await fetch(
        `${apiURL}/api.php/stat/reportWin8?token=${token}&presentations_shown[1]=${presentation.id}&startdates[1]=${
          presentation.startDate
        }&is_custom[1]=${presentation.custom}&timelines[1]=${str}&audience_types[1]=${presentation.audience || 4}`
      );
      dispatch(postReportPresentationSuccess());
      await setHandler(`order-${presentation.id}`, []);
    } catch (error) {
      await updateObjectHandler(`presentations-report`, {
        id: presentation.id,
        startDate: presentation.startDate,
        endDate: Date.now(),
        quantity: presentation.quantity.length,
      });
      dispatch(postReportPresentationFailure(error?.response?.data));
    }
  };
};

export const postReportFile = (file) => {
  return async (dispatch, getState) => {
    const token = getState().auth.user.token;
    try {
      dispatch(postReportFileRequest());
      await fetch(
        `${apiURL}/api.php/stat/reportFiles?token=${token}&id[1]=${file.id}&startTime[1]=${file.startDate}&stopTime[1]=${file.endDate}`
      );
      dispatch(postReportFileSuccess());
    } catch (error) {
      dispatch(postReportFileFailure(error?.response?.data));
      await updateObjectHandler('report-files', {
        id: file.id,
        startDate: file.startDate,
        endDate: file.endDate,
      });
    }
  };
};

export const postReportClinicalPapers = (file) => {
  return async (dispatch, getState) => {
    const token = getState().auth.user.token;
    try {
      dispatch(postReportFileRequest());
      await fetch(
        `${apiURL}/api.php/stat/reportClinicalPapers?token=${token}&id[1]=${file.id}&startTime[1]=${file.startDate}&stopTime[1]=${file.endDate}`
      );
      dispatch(postReportFileSuccess());
    } catch (error) {
      await updateObjectHandler(`report-clinical`, {
        id: file.id,
        startDate: file.startDate,
        endDate: file.endDate,
      });
      dispatch(postReportFileFailure(error.response.data));
    }
  };
};
