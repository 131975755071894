import {
  GET_AUDIENCE_FAILURE,
  GET_AUDIENCE_REQUEST,
  GET_AUDIENCE_SUCCESS,
  GET_CUSTOM_SUCCESS,
  GET_PRESENTATION_FAILURE,
  GET_PRESENTATION_REQUEST,
  GET_PRESENTATION_SUCCESS,
  GET_PRESENTATIONS_FAILURE,
  GET_PRESENTATIONS_REQUEST,
  GET_PRESENTATIONS_SUCCESS,
  LOGOUT_USER,
} from '../actionTypes';

export const initialState = {
  presentations: [],
  presentation: {},
  e_detailer: {},
  custom: [],
  loading: false,
  error: null,
  audiences: [],
};

const presentationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRESENTATIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PRESENTATIONS_SUCCESS:
      return { ...state, loading: false, presentations: action.presentations, error: null };
    case GET_PRESENTATIONS_FAILURE:
      return { ...state, loading: false, error: action.error, presentations: action.presentations };
    case GET_PRESENTATION_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PRESENTATION_SUCCESS:
      return { ...state, loading: false, presentation: action.presentation, e_detailer: action.e_detailer, error: null };
    case GET_PRESENTATION_FAILURE:
      return { ...state, loading: false, error: null };
    case GET_AUDIENCE_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_AUDIENCE_SUCCESS:
      return { ...state, loading: false, audiences: action.audiences, error: null };
    case GET_AUDIENCE_FAILURE:
      return { ...state, loading: false, error: action.error, audiences: action.audiences || [] };
    case GET_CUSTOM_SUCCESS:
      return { ...state, custom: action.custom };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default presentationReducer;
