import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from 'react-redux';
import store from './store';
import {Router} from 'react-router-dom';
import history from './utils/history';
import 'antd/dist/antd.min.css';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import PWAPrompt from 'react-ios-pwa-prompt'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router history={history}>
      <App/>
      <PWAPrompt />
    </Router>
  </Provider>
);

serviceWorkerRegistration.register();
