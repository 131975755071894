import React, { useRef, useState } from 'react';
import Image from '../Image/Image';
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside';
import './styles.scss';

const List = ({ setState, slides, setList, setShow }) => {
  const [active, setActive] = useState(0);
  const refList = useRef();
  useOnClickOutside(refList, () => {
    setList(false);
    setShow(false);
  });

  const navigationHandler = (index) => {
    setActive(index);
    setState(index);
  };

  return (
    <div className="list" ref={refList}>
      {slides.map((list, index) => (
        <div key={index} className={`list__item ${active === index ? 'list__item-active' : ''}`} onClick={() => navigationHandler(index)}>
          <Image
            src={list.type === 'video' || list.type === 'pdf' ? list.preview_url : list.url}
            localUrl={
              list.localPreview ? URL.createObjectURL(list.localPreview) : list.localUrl ? URL.createObjectURL(list.localUrl) : null
            }
          />
          <span>{index + 1}.</span>
          <p>{list.title}</p>
        </div>
      ))}
    </div>
  );
};

export default List;
