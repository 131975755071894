import React, { useMemo, useState } from 'react';
import { CloseOutlined, EditOutlined, ScheduleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import List from '../List/List';
import history from '../../utils/history';
import Customize from '../Customize/Customize';
import Audience from '../Audience/Audience';
import { useHistory } from 'react-router-dom';
import './styles.scss';

const NavigationTop = ({ slides, id, show, setShow, setState, notes, setNotes, setAudienceReport, setSlides }) => {
  const pathname = useHistory().location.pathname;
  const [list, setList] = useState(false);
  const [customize, setCustomize] = useState(false);
  const [audience, setAudience] = useState(false);

  const navigationsSlide = [
    {
      id: 1,
      title: 'Audience',
      icon: <UnorderedListOutlined className="nav__item-icon" />,
      onClick: () => {
        setAudience((prev) => !prev);
        setList(false);
        setCustomize(false);
      },
    },
    {
      id: 2,
      title: 'Customize',
      icon: <EditOutlined className="nav__item-icon" />,
      onClick: () => {
        setAudience(false);
        setList(false);
        setCustomize(true);
      },
    },
    {
      id: 3,
      title: 'Notes',
      icon: <ScheduleOutlined className="nav__item-icon" />,
      onClick: () => {
        setNotes((prev) => !prev);
        setAudience(false);
        setList(false);
        setCustomize(false);
      },
    },
    {
      id: 5,
      title: 'List',
      icon: <UnorderedListOutlined className="nav__item-icon" />,
      onClick: () => {
        setAudience(false);
        setList((prev) => !prev);
        setCustomize(false);
      },
    },
    {
      id: 6,
      title: 'Close',
      icon: <CloseOutlined className="nav__item-icon" />,
      onClick: () => history.push('/presentations'),
    },
  ];

  const notesNavigation = [
    {
      id: 3,
      title: 'View',
      icon: <ScheduleOutlined className="nav__item-icon" />,
      onClick: () => setNotes(!notes),
    },
    {
      id: 6,
      title: 'Close',
      icon: <CloseOutlined className="nav__item-icon" />,
      onClick: () => history.push('/presentations'),
    },
  ];

  const navigation = notes ? notesNavigation : navigationsSlide;

  const mouseHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (list || audience) {
      setShow(true);
    } else {
      setShow(false);
      setList(false);
    }
  };

  const navigationMemo = useMemo(() => {
    if (pathname.includes('custom')) {
      return navigation.filter((item) => item.title !== 'Customize');
    } else {
      return navigation;
    }
  }, [pathname, navigation]);

  return (
    <>
      <nav
        className={show ? 'nav-top-active' : 'nav-top'}
        onMouseEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShow(true);
        }}
        onMouseLeave={mouseHandler}
      >
        <div className="nav-top-active__inner">
          {navigationMemo.map((nav) => (
            <div
              key={nav.id}
              className="nav-top__item"
              style={{ border: show && nav.title === 'Close' ? '1px solid white' : null }}
              onClick={!show ? null : nav.onClick}
            >
              {nav.icon}
              <p>{nav.title}</p>
            </div>
          ))}
        </div>
      </nav>
      {!notes && list && <List setState={setState} slides={slides} setList={setList} setShow={setShow} />}
      {customize && <Customize id={id} setShow={setCustomize} slides={slides} setSlides={setSlides} />}
      {!notes && audience && <Audience setShow={setAudience} setAudience={setAudienceReport} />}
    </>
  );
};

export default NavigationTop;
