import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ArrowLeftOutlined, SearchOutlined, MailOutlined } from '@ant-design/icons';
import { get } from '../../utils/idb-config';
import Image from '../Image/Image';
import { useDispatch } from 'react-redux';
import { postEmail } from '../../store/actions/senderActions';
import SearchSender from '../SearchSender/SearchSender';
import { AppContext } from '../../utils/context';
import { showNotification } from '../../App';
import { useHistory } from 'react-router-dom';
import './styles.scss';

const SendEmail = () => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [filesIds, setFilesIds] = useState([]);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { setEmailSender, emailSearch, setEmailSearch } = useContext(AppContext);
  const pathname = useHistory().location.pathname;

  useEffect(() => {
    if (!pathname.includes('files') && !pathname.includes('clinical-papers')) setEmailSender(false);
  }, [pathname, setEmailSender]);

  useEffect(() => {
    const effect = async () => {
      const filesDb = (await get('files-all-folders')) || [];

      setFiles(filesDb);
    };

    effect().then((r) => r);
  }, []);

  const addFileHandler = (id) => {
    setFiles((prev) => prev.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item)));

    setFilesIds((prev) => {
      const response = prev.filter((item) => item === id);
      if (response.length) {
        return prev.filter((item) => item !== id);
      } else return [...prev, id];
    });
  };

  const submitHandler = () => {
    if (!filesIds.length) showNotification('error', 'Please select at least 1 file', 'Error');
    else if (email.trim().length > 5 && email.includes('@')) {
      const response = dispatch(postEmail(email, filesIds));
      if (response) {
        setFiles([]);
        setEmail('');
        setMessage('');
        setEmailSender(false);
      }
    } else showNotification('error', 'Email must contain "@" and be longer than 5 characters', 'Error');
  };

  const filesList = useMemo(() => {
    return files.map((item, index) => (
      <div
        key={index}
        className={`email-send__body-list-item ${item.checked ? 'email-send__body-list-item--active' : ''}`}
        onClick={() => addFileHandler(item.id)}
      >
        <div className="email-send__img">
          <Image src={item?.thumbnail} localUrl={item?.localUrlBase64 || item?.localUrl} />
        </div>
        <p>{item.name}</p>
      </div>
    ));
  }, [files]);

  return (
    <>
      <div className="email-send">
        <div className="email-send__header">
          <div className="email-send__header-inner">
            <ArrowLeftOutlined className="settings__modal-icon" onClick={() => setEmailSender(false)} />
            <h3>Mail</h3>
          </div>
          <div className="email-send__header-inner">
            <SearchOutlined
              className="settings__modal-icon"
              onClick={() => {
                setEmailSearch(true);
              }}
            />
            <button onClick={submitHandler}>Send</button>
          </div>
        </div>

        <div className="email-send__body">
          <label className="email-send__label">
            <p>To :</p>
            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
            <button className="email-send__label-btn">
              <MailOutlined style={{ color: 'white' }} />
            </button>
          </label>

          <label className="email-send__label">
            <p>Message :</p>
            <textarea name="" id="" cols="30" rows="10" value={message} onChange={(e) => setMessage(e.target.value)} />
          </label>

          <div className="email-send__body-list">{filesList}</div>
        </div>
      </div>

      {emailSearch && (
        <SearchSender
          addFileHandler={addFileHandler}
          setEmailSender={setEmailSender}
          setEmailSearch={setEmailSearch}
          files={files}
          setFiles={setFiles}
          setFilesIds={setFilesIds}
        />
      )}
    </>
  );
};

export default SendEmail;
