import { useLayoutEffect, useState } from 'react';
import { loadFromLocalStorage, saveToLocalStorage } from '../localStorage';

export const useFonts = () => {
  const [fonts, setFonts] = useState(loadFromLocalStorage('fonts') || 'medium');
  useLayoutEffect(() => {
    document.documentElement.setAttribute('data-fonts', fonts);
    saveToLocalStorage('fonts', fonts);
  }, [fonts]);

  return { fonts, setFonts };
};
