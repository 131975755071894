import React, { memo, useEffect, useState } from 'react';
import Footer from '../../Components/Footer/Footer';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined, DesktopOutlined, FileOutlined, FolderOutlined, SearchOutlined } from '@ant-design/icons';
import history from '../../utils/history';
import Fuse from 'fuse.js';
import { getPresentationSuccess } from '../../store/actions/presentationActions';
import { viewPdf } from '../../store/actions/foldersActions';
import { get } from '../../utils/idb-config';

import './styles.scss';

const Search = () => {
  const dispatch = useDispatch();
  const [presentations, setPresentations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const effect = async () => {
      setLoading(true);
      const response = await get('allFiles');
      setPresentations(response);
      setFiles(response);
    };

    effect().finally(() => setLoading(false));
  }, []);

  const searchHandler = () => {
    const options = {
      findAllMatches: true,
      keys: ['data.name', 'name'],
    };

    const fuse = new Fuse(presentations, options);

    const result = fuse.search(search).map((item) => item.item);

    setFiles(result);
  };

  const onKeyDownHandler = (event) => {
    if (event.key === 'Enter') searchHandler();
  };

  useEffect(() => {
    if (!search.trim().length) setFiles(presentations);
  }, [search, presentations]);

  return (
    <div className="content">
      {!loading ? (
        <div className="search">
          <div className="search__content">
            <h2>Search</h2>
            <div className="search__list">
              {files && files.length
                ? files.map((item, index) => {
                    if (item.flag === 'Clinical Paper Folders') return null;

                    return (
                      <Link
                        to={
                          item.e_detailer
                            ? `e-detailer/view/${item?.data?.id}`
                            : item.flag === 'Presentation'
                            ? `/presentations/view/${item?.data?.id}`
                            : `/pdf/view/${item.id}`
                        }
                        className="search__list-item"
                        key={index}
                        onClick={() =>
                          item.flag === 'Presentation' ? dispatch(getPresentationSuccess(item.slides)) : dispatch(viewPdf(item))
                        }
                      >
                        <p>
                          {item.flag === 'Presentation' ? (
                            <DesktopOutlined className="search__list-icon" />
                          ) : item.flag === 'Clinical Paper' ? (
                            <FileOutlined className="search__list-icon" />
                          ) : (
                            <FolderOutlined className="search__list-icon" />
                          )}
                        </p>
                        <p className="search__list-flag">{item?.e_detailer ? 'E-Detailer' : item.flag || 'File'}</p>
                        <p>{item?.data?.name || item.name}</p>
                      </Link>
                    );
                  })
                : null}
            </div>
          </div>

          <div className="search__panel">
            <h2>
              <ArrowLeftOutlined className="search-icon" onClick={() => history.push('/')} /> Search
            </h2>
            <label className="search__panel-label">
              <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={onKeyDownHandler} />
              <button onClick={searchHandler}>
                <SearchOutlined />
              </button>
            </label>
          </div>
        </div>
      ) : (
        <div className="spinner-container">
          <Spin spinning={loading} className="spinner" size="large" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default memo(Search);
