import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAudienceTypes } from '../../store/actions/presentationActions';
import './styles.scss';

const Audience = ({ setAudience }) => {
  const dispatch = useDispatch();
  const audiences = useSelector((state) => state.presentation.audiences) || [];
  const [current, setCurrent] = useState('');
  const ref = useRef();

  useEffect(() => {
    dispatch(getAudienceTypes());
  }, [dispatch]);

  return (
    <div className="presentation-audience" ref={ref}>
      <ul className="presentation-audience-list">
        {audiences.length
          ? audiences.map((audience) => (
              <li
                className={`presentation-audience-list-item ${current === audience.id ? 'presentation-audience-list-item__active' : ''}`}
                key={audience.id}
                onClick={() => {
                  setCurrent(audience.id);
                  setAudience(audience.id);
                }}
              >
                {audience.name}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

export default Audience;
