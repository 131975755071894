import React, {useState} from 'react';
import AuthField from '../../Components/AuthField/AuthField';
import {useDispatch} from 'react-redux';
import {resetPassword} from '../../store/actions/authActions';
import './styles.scss';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const submitHandler = () => {
    const formData = new FormData();
    formData.append('confirmPassword', confirmPassword);
    formData.append('password', password);
    dispatch(resetPassword(formData));
  };

  return (
    <div className="auth">
      <div className="auth__body">
        <AuthField label="Enter a new password" type="password" value={password} setValue={setPassword} placeholder="Enter password" />
        <AuthField label="Confirm the  password" type="password" value={confirmPassword} setValue={setConfirmPassword} placeholder="Enter password" />
        <button className="auth__button" onClick={submitHandler}>
          Submit
        </button>
      </div>

      <div className="auth__footer"></div>
    </div>
  );
};

export default ResetPassword;
