import React from 'react';
import { imgURL } from '../../utils/config';
import NoImage from '../../assets/no-images.png';

const Image = ({ videoPreview, localUrl, src, onClick = null, className = '', ref = null }) => {
  return (
    <img
      className={className}
      ref={ref}
      src={localUrl ? localUrl : imgURL + src}
      onError={(e) => (e.target.src = localUrl ? localUrl : videoPreview ? videoPreview : NoImage)}
      alt="slide"
      onClick={onClick}
      draggable={false}
    />
  );
};

export default Image;
