import React, { memo, useEffect, useState } from 'react';
import Footer from '../../Components/Footer/Footer';
import Card from '../../Components/Card.js/Card';
import { Spin } from 'antd';
import { getCustom } from '../../store/actions/presentationActions';
import { useDispatch, useSelector } from 'react-redux';

const Custom = () => {
  const dispatch = useDispatch();
  const custom = useSelector((state) => state.presentation.custom);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getCustom(setLoading));
  }, [dispatch]);

  return (
    <div className="content">
      <div className="main-block">
        <h1>Custom</h1>
        <div className="main-block__content">
          {custom.length
            ? custom.map((presentation, index) => (
                <Card
                  description={presentation.data.subject}
                  localUrl={presentation.data.localUrl || presentation.data.localUrlBase64}
                  path="presentations/custom"
                  id={presentation.data.id}
                  key={presentation.data.id + index}
                  url={presentation.data.mobile_thumbnail}
                  title={presentation.data.name}
                  date={presentation.data.date_published}
                  slides={presentation.slides}
                  to={`/presentations/custom/view/${presentation.data.id}`}
                  starsDb={'custom-stars'}
                  setLoading={setLoading}
                  download={presentation.slides}
                />
              ))
            : null}
        </div>
      </div>
      {loading && (
        <div className="spinner-container">
          <Spin spinning={loading} className="spinner" size="large" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default memo(Custom);
