//Auth ACTIONS
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const FORGOT_USER_REQUEST = 'FORGOT_USER_REQUEST';
export const FORGOT_USER_SUCCESS = 'FORGOT_USER_SUCCESS';
export const FORGOT_USER_FAILURE = 'FORGOT_USER_FAILURE';

export const RESET_USER_REQUEST = 'RESET_USER_REQUEST';
export const RESET_USER_SUCCESS = 'RESET_USER_SUCCESS';
export const RESET_USER_FAILURE = 'RESET_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

//Card ACTIONS

export const GET_PRESENTATIONS_REQUEST = 'GET_PRESENTATIONS_REQUEST';
export const GET_PRESENTATIONS_SUCCESS = 'GET_PRESENTATIONS_SUCCESS';
export const GET_PRESENTATIONS_FAILURE = 'GET_PRESENTATIONS_FAILURE';

export const GET_PRESENTATION_REQUEST = 'GET_PRESENTATION_REQUEST';
export const GET_PRESENTATION_SUCCESS = 'GET_PRESENTATION_SUCCESS';
export const GET_PRESENTATION_FAILURE = 'GET_PRESENTATION_FAILURE';

export const GET_CLINICAL_PAPERS_REQUEST = 'GET_CLINICAL_PAPERS_REQUEST';
export const GET_CLINICAL_PAPERS_SUCCESS = 'GET_CLINICAL_PAPERS_SUCCESS';
export const GET_CLINICAL_PAPERS_FAILURE = 'GET_CLINICAL_PAPERS_FAILURE';

///Folders

export const GET_FOLDERS_REQUEST = 'GET_FOLDERS_REQUEST';
export const GET_FOLDERS_SUCCESS = 'GET_FOLDERS_SUCCESS';
export const GET_FOLDERS_FAILURE = 'GET_FOLDERS_FAILURE';

export const GET_FOLDER_BY_ID_REQUEST = 'GET_FOLDER_BY_ID_REQUEST';
export const GET_FOLDER_BY_ID_SUCCESS = 'GET_FOLDER_BY_ID_SUCCESS';
export const GET_FOLDER_BY_ID_FAILURE = 'GET_FOLDER_BY_ID_FAILURE';

// SEARCH

export const GET_ALL_REQUEST = 'GET_ALL_REQUEST';
export const GET_ALL_SUCCESS = 'GET_ALL_SUCCESS';
export const GET_ALL_FAILURE = 'GET_ALL_FAILURE';

// REPORTS

export const POST_REPORT_FILE_REQUEST = 'POST_REPORT_FILE_REQUEST';
export const POST_REPORT_FILE_SUCCESS = 'POST_REPORT_FILE_SUCCESS';
export const POST_REPORT_FILE_FAILURE = 'POST_REPORT_FILE_FAILURE';

export const POST_REPORT_PRESENTATION_REQUEST = 'POST_REPORT_PRESENTATION_REQUEST';
export const POST_REPORT_PRESENTATION_SUCCESS = 'POST_REPORT_PRESENTATION_SUCCESS';
export const POST_REPORT_PRESENTATION_FAILURE = 'POST_REPORT_PRESENTATION_FAILURE';

export const GET_AUDIENCE_REQUEST = 'GET_AUDIENCE_REQUEST';
export const GET_AUDIENCE_SUCCESS = 'GET_AUDIENCE_SUCCESS';
export const GET_AUDIENCE_FAILURE = 'GET_AUDIENCE_FAILURE';

export const PDF_UPLOAD = 'PDF_UPLOAD';

//CUSTOM

export const GET_CUSTOM_SUCCESS = 'GET_CUSTOM_SUCCESS';


// SENDER EMAIL

export const POST_EMAIL_SENDER_REQUEST = 'POST_EMAIL_SENDER_REQUEST';
export const POST_EMAIL_SENDER_SUCCESS = 'POST_EMAIL_SENDER_SUCCESS';
export const POST_EMAIL_SENDER_FAILURE = 'POST_EMAIL_SENDER_FAILURE';

// SIGNAL

export const REFRESH_PRESENTATIONS = 'REFRESH_PRESENTATIONS';
export const REFRESH_FOLDERS = 'REFRESH_FOLDERS';
export const REFRESH_FILES_SUCCESS = 'REFRESH_FILES_SUCCESS';
export const REFRESH_FILES_REQUEST = 'REFRESH_FILES_REQUEST';

export const CLINICAL_FOLDERS = 'CLINICAL_FOLDERS';
export const CLINICAL_FILES = 'CLINICAL_FILES';