import React, { useEffect, useMemo, useState } from 'react';
import Image from '../Image/Image';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Fuse from 'fuse.js';

const SearchSender = ({ files, setEmailSearch, addFileHandler }) => {
  const [search, setSearch] = useState('');
  const [filterFiles, setFilterFiles] = useState([]);

  const searchHandler = () => {
    const options = {
      findAllMatches: true,
      keys: ['name'],
    };

    const fuse = new Fuse(files, options);

    const result = fuse.search(search).map((item) => item.item);

    setFilterFiles(result);
  };

  useEffect(() => {
    setFilterFiles(files);
  }, [files]);

  useEffect(() => {
    if (!search.trim().length) setFilterFiles(files);
  }, [search, files]);

  const onKeyDownHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.key === 'Enter') searchHandler();
    else setSearch((prev) => prev + event.key);
  };

  const filesList = useMemo(() => {
    return filterFiles.map((item, index) => (
      <div
        key={index}
        className={`email-send__body-list-item ${item.checked ? 'email-send__body-list-item--active' : ''}`}
        onClick={() => addFileHandler(item.id)}
      >
        <div className="email-send__img">
          <Image src={item?.thumbnail} />
        </div>
        <p>{item.name}</p>
      </div>
    ));
  }, [filterFiles, addFileHandler]);

  return (
    <div className="email-send">
      <div className="email-send__header pt-10">
        <div className="email-send__header-inner">
          <ArrowLeftOutlined className="settings__modal-icon" onClick={() => setEmailSearch(false)} />
          <h3>Search</h3>
        </div>
        <div className="email-send-search">
          <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} onKeyPress={onKeyDownHandler} />
          <button onClick={searchHandler}>Search</button>
        </div>
      </div>

      <div className="email-send-search-list">{filesList}</div>
    </div>
  );
};

export default SearchSender;
