import React from 'react';
import { Modal } from 'antd';
import parse from 'html-react-parser';
import { imgURL } from '../../utils/config';
import './styles.scss';

const References = ({ visible, setReferences, slides, currentSlide }) => {
  return (
    <>
      {slides[currentSlide].reference_type === 'text' && (
        <Modal className="references__modal" visible={visible} onCancel={() => setReferences(false)} title="References" footer={null}>
          <div className="references">{parse(slides[currentSlide]?.reference_content || '')}</div>
        </Modal>
      )}
      {slides[currentSlide].reference_type === 'image' && (
        <div className="references__modalImg">
          <img
            className="references__modalImg_img"
            src={
              slides[currentSlide].referenceLocalUrl
                ? URL.createObjectURL(slides[currentSlide].referenceLocalUrl)
                : imgURL + slides[currentSlide]?.reference_content
            }
            alt="slide"
            onClick={() => setReferences(false)}
            draggable={false}
          />
        </div>
      )}
    </>
  );
};

export default References;
