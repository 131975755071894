import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/authActions';
import AuthField from '../../Components/AuthField/AuthField';
import { Link } from 'react-router-dom';
import LogoIcon from '../../assets/logo.png';
import { Spin } from 'antd';
import { LOGOUT_USER } from '../../store/actionTypes';
import './styles.scss';

const Login = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const submitHandler = () => {
    dispatch(login({ username, password }));
  };

  const onKeyDownHandler = (event) => {
    event.stopPropagation();
    if (event.key === 'Enter') submitHandler();
  };

  useEffect(() => {
    dispatch({ type: LOGOUT_USER });
  }, [dispatch]);

  return (
    <div className="auth" tabIndex="0">
      <div className="auth__body">
        <Spin spinning={loading} size="large">
          <img src={LogoIcon} alt="Logo" />
          <AuthField
            error={error}
            type="text"
            name="email"
            value={username}
            setValue={setUserName}
            placeholder="Enter username"
            onKeyDown={onKeyDownHandler}
          />
          <AuthField
            error={error}
            name="password"
            type="password"
            value={password}
            setValue={setPassword}
            placeholder="Enter password"
            onKeyDown={onKeyDownHandler}
          />
          <button className="auth__button" onClick={submitHandler}>
            Login
          </button>
          <Link to="/forgot-password">Forgot Password?</Link>
        </Spin>
      </div>
      <div className="auth__footer" />
    </div>
  );
};

export default Login;
