import {
  CLINICAL_FOLDERS,
  GET_ALL_FAILURE,
  GET_ALL_REQUEST,
  GET_ALL_SUCCESS,
  REFRESH_FILES_REQUEST,
  REFRESH_FILES_SUCCESS,
  REFRESH_FOLDERS,
  REFRESH_PRESENTATIONS
} from "../actionTypes";
import axiosApi from "../../utils/axios";
import { get } from "../../utils/idb-config";
import {
  clinicalPapersHandler,
  convertImageUrlToBase64,
  foldersHandler,
  pdfToBase64,
  presentationsHandler,
  setHandler,
  toDataURLAsync,
  updateArrayHandler
} from "../../utils/functions";
import { imgURL } from "../../utils/config";
import { controller } from "../../utils/axiosController";

const getAllRequest = (files) => ({ type: GET_ALL_REQUEST, files });
const getAllSuccess = (files) => ({ type: GET_ALL_SUCCESS, files });
const getAllFailure = (error, files) => ({ type: GET_ALL_FAILURE, error, files });

const getFilesByFolder = async (folders, token, dispatch) => {
  const array = [];

  for (const folder of folders) {
    try {
      const response = await axiosApi.get(`/files/getFiles?token=${token}&folder_id=${folder.id}`, { signal: controller.signal });
      await dispatch({ type: REFRESH_FILES_REQUEST, id: folder.id });

      const filesAsync = await Promise.all(
        response.data.body.files.map(async (item) => {
          try {
            const dataUrl = await toDataURLAsync(imgURL + item?.path);
            const localPathBase64 = await pdfToBase64(dataUrl);
            const localUrl = await toDataURLAsync(imgURL + item?.thumbnail);
            const thumbnailBase64 = await convertImageUrlToBase64(imgURL + item?.thumbnail);

            return {
              ...item,
              localPath: dataUrl,
              localPathBase64,
              localUrl,
              localThumbnail: localUrl,
              localUrlBase64: thumbnailBase64,
              thumbnailBase64,
            };
          } catch (e) {
            console.log(e);
          }
        })
      );

      array.push(...filesAsync);

      await setHandler(`fileByFolder-${folder.id}`, filesAsync);
    } catch (error) {
      console.log(error);
    }
  }

  await updateArrayHandler('files-all-folders', array);
  await dispatch({ type: REFRESH_FILES_SUCCESS });

  return array;
};

export const getAll = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.user.token;
    try {
      const response = (await get('allFiles')) || [];
      dispatch(getAllRequest(response));

      const {
        data: {
          body: { presentations },
        },
      } = await axiosApi.get(`/presentations/fetchUpdated?token=${token}`, { signal: controller.signal });
      const presentationsAsync = await presentationsHandler(presentations);
      await setHandler('presentations', presentationsAsync);
      await dispatch({ type: REFRESH_PRESENTATIONS });
      const {
        data: {
          body: {
            clinical_papers: {
              new: { folders: clinicalPapers },
            },
          },
        },
      } = await axiosApi.get(`/files/getClinicalPapers?token=${token}`, { signal: controller.signal });
      const clinicalPapersAsync = await clinicalPapersHandler(clinicalPapers);

      const {
        data: {
          body: { folders },
        },
      } = await axiosApi.get(`/folders/getFolders?token=${token}`, { signal: controller.signal });

      const foldersAsync = await foldersHandler(folders);

      await setHandler('folders', foldersAsync);
      await setHandler('clinicalPapers', clinicalPapersAsync);

      await dispatch({ type: REFRESH_FOLDERS });
      await dispatch({ type: CLINICAL_FOLDERS });

      const files = (await get('files-clinical-papers')) || [];

      getFilesByFolder(foldersAsync, token, dispatch).then(async (response) => {
        await setHandler('allFiles', [...presentationsAsync, ...clinicalPapersAsync, ...response, ...files]);
        await dispatch(getAllSuccess([...presentationsAsync, ...clinicalPapersAsync, ...response, ...files]));
      });
    } catch (error) {
      const response = (await get('allFiles')) || [];
      dispatch(getAllFailure(error, response));
    }
  };
};
