import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import Footer from '../../Components/Footer/Footer';
import Card from '../../Components/Card.js/Card';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getClinicalPapers } from '../../store/actions/foldersActions';
import { AppContext } from '../../utils/context';

const ClinicalPapers = () => {
  const dispatch = useDispatch();
  const folders = useSelector((state) => state.folders?.clinicalPapers);
  const loading = useSelector((state) => state.folders.loading);
  const [show, setShow] = useState(false);
  const { setEmailSender, emailSender } = useContext(AppContext);

  useEffect(() => {
    dispatch(getClinicalPapers());
  }, [dispatch]);

  const cardBlock = useMemo(() => {
    return folders.length
      ? folders.map((folder, index) => (
          <Card
            type={false}
            to={`/clinical-papers/view/${folder.id}`}
            path="clinical-papers"
            id={folder.id}
            key={folder.id + index}
            url={folder.thumbnail}
            title={folder.name}
            date={folder.date_published}
            folder
            starsDb="clinical-papers-stars"
            download={{ localUrl: true }}
            description={folder.subject}
            localUrl={folder.localUrl || folder.localLogoBase64}
          />
        ))
      : null;
  }, [folders]);

  return (
    <div className="content">
      <div className="main-block">
        <div
          className={show && !emailSender ? 'nav-top-active' : 'nav-top'}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <div className="nav-top__item" onClick={() => setEmailSender(true)}>
            <MailOutlined className="nav__item-icon" />
            <p>Email</p>
          </div>
        </div>
        <h1>
          <Link to={'/presentations'}>
            <ArrowLeftOutlined className="back-icon" />
          </Link>
          Clinical Papers
        </h1>
        <div className="main-block__content">{cardBlock}</div>
      </div>
      {loading && (
        <div className="main-block__spinner-container">
          <Spin spinning className="spinner" size="large" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default memo(ClinicalPapers);
