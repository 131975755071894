import React, { useState } from 'react';
import {
  SettingOutlined,
  FolderOutlined,
  FileOutlined,
  CaretRightOutlined,
  DownloadOutlined,
  SearchOutlined,
  UndoOutlined,
  DesktopOutlined,
  HddOutlined
} from '@ant-design/icons';
import SettingsModal from '../SettingsModal/SettingsModal';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAll } from '../../store/actions/searchActions';
import './styles.scss';

const links = [
  {
    title: 'Presentations',
    icon: <DesktopOutlined className="icon" />,
    to: '/presentations',
  },
  {
    title: 'Folders',
    icon: <FolderOutlined className="icon" />,
    to: '/files',
  },
  {
    title: 'Clinical papers',
    icon: <FileOutlined className="icon" />,
    to: '/clinical-papers',
  },
  {
    title: 'Videos',
    icon: <CaretRightOutlined className="icon" />,
    to: '/video',
  },
  {
    title: 'E-Detailer',
    icon: <HddOutlined className="icon" />,
    to: '/e-detailer',
  },
  {
    title: 'Custom',
    icon: <DownloadOutlined className="icon" />,
    to: '/custom',
  },
];

const Footer = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const loading = useSelector((state) => state.search.loading);

  const updateHandler = () => {
    if (!loading) dispatch(getAll());
  };

  return (
    <footer className="footer text-center">
      <div className="footer__inner">
        <ul className="footer__nav">
          {links.map((link, index) => (
            <li key={index}>
              <Link to={link.to}>
                {link.icon}
                <p>{link.title}</p>
              </Link>
            </li>
          ))}
        </ul>

        <ul className="footer__nav footer__nav-second">
          <li>
            <Link to={'/search'}>
              <SearchOutlined className="icon" />
              <p>Search</p>
            </Link>
          </li>
          <li onClick={updateHandler}>
            <UndoOutlined className={`icon ${loading ? 'icon-spin' : ''}`} />
            <p>Update</p>
          </li>
          <li onClick={() => setShow(true)}>
            <SettingOutlined className="icon" />
            <p>Settings</p>
          </li>
        </ul>
      </div>

      <SettingsModal show={show} setShow={setShow} />
    </footer>
  );
};

export default Footer;
