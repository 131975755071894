import {
  FORGOT_USER_FAILURE,
  FORGOT_USER_REQUEST,
  FORGOT_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  RESET_USER_FAILURE,
  RESET_USER_REQUEST,
  RESET_USER_SUCCESS,
} from '../actionTypes';
import axiosApi from '../../utils/axios';
import { saveToLocalStorage } from '../../utils/localStorage';
import history from '../../utils/history';
import { showNotification } from '../../App';
import { capitalizeFirstLetter } from '../../utils/functions';
import { controller } from '../../utils/axiosController';

const loginUserRequest = () => ({ type: LOGIN_USER_REQUEST });
const loginUserSuccess = (user) => ({ type: LOGIN_USER_SUCCESS, user });
const loginUserFailure = (error) => ({ type: LOGIN_USER_FAILURE, error });

const forgotUserRequest = () => ({ type: FORGOT_USER_REQUEST });
const forgotUserSuccess = () => ({ type: FORGOT_USER_SUCCESS });
const forgotUserFailure = (error) => ({ type: FORGOT_USER_FAILURE, error });

const resetUserRequest = () => ({ type: RESET_USER_REQUEST });
const resetUserSuccess = () => ({ type: RESET_USER_SUCCESS });
const resetUserFailure = (error) => ({ type: RESET_USER_FAILURE, error });

export const login = (userData) => {
  return async (dispatch) => {
    try {
      dispatch(loginUserRequest());
      const response = await axiosApi.get(`/login?username=${userData.username}&password=${userData.password}`);
      if (response.data.status !== 'AuthenticationError') dispatch(loginUserSuccess(response.data.body));
      else {
        showNotification('error', capitalizeFirstLetter(response.data.body), 'Error');
        dispatch(loginUserFailure(response.data.body));
      }
      if (response.data.body.token) history.push('/presentations');
    } catch (error) {
      dispatch(loginUserFailure(error.response.data.body));
    }
  };
};

export const forgotPassword = (email) => {
  return async (dispatch) => {
    try {
      dispatch(forgotUserRequest());
      await axiosApi.get(`/auth/resetPassword?email=${email}`);
      dispatch(forgotUserSuccess());
      history.push('/');
    } catch (error) {
      dispatch(forgotUserFailure(error.response.data.body));
    }
  };
};

export const resetPassword = (token, userData) => {
  return async (dispatch) => {
    try {
      dispatch(resetUserRequest());
      await axiosApi.post(`/reset-password/${token}`, userData);
      dispatch(resetUserSuccess());
    } catch (error) {
      dispatch(resetUserFailure(error.response.data.body));
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch({ type: LOGOUT_USER });
    saveToLocalStorage('auth', null);
    controller.abort();
    window.location.reload();
  };
};
