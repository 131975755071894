import React from 'react';

export const AppContext = React.createContext();

export function AppContextProvider({ children, emailSender, setEmailSender, emailSearch, setEmailSearch }) {
  return (
    <AppContext.Provider
      value={{
        emailSender,
        setEmailSender,
        emailSearch,
        setEmailSearch
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
