import {
  FORGOT_USER_FAILURE,
  FORGOT_USER_REQUEST,
  FORGOT_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  RESET_USER_FAILURE,
  RESET_USER_REQUEST,
  RESET_USER_SUCCESS,
} from '../actionTypes';

export const initialState = {
  user: null,
  loading: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, user: action.user, error: null };
    case LOGIN_USER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case FORGOT_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case FORGOT_USER_SUCCESS:
      return { ...state, loading: false, error: null };
    case FORGOT_USER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case RESET_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case RESET_USER_SUCCESS:
      return { ...state, loading: false, error: null };
    case RESET_USER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default authReducer;
