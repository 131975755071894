import { POST_EMAIL_SENDER_FAILURE, POST_EMAIL_SENDER_REQUEST, POST_EMAIL_SENDER_SUCCESS } from '../actionTypes';
import axiosApi from '../../utils/axios';
import { showNotification } from '../../App';
import { updateObjectHandler } from '../../utils/functions';

const postEmailFilesRequest = () => ({ type: { POST_EMAIL_SENDER_REQUEST } });
const postEmailFilesSuccess = () => ({ type: { POST_EMAIL_SENDER_SUCCESS } });
const postEmailFilesFailure = (error) => ({ type: { POST_EMAIL_SENDER_FAILURE, error } });

export const postEmail = (email, filesIds, message = true) => {
  let str = '';

  for (let i = 0; i < filesIds.length; i++) {
    str += `file_id[${i}]=${filesIds[i]}&`;
  }

  return async (dispatch, getState) => {
    const token = getState().auth.user.token;
    try {
      dispatch(postEmailFilesRequest());
      await axiosApi.post(`/email/sendFile?token=${token}&email=${email}&${str}`);
      dispatch(postEmailFilesSuccess());
      message && showNotification('success', 'Files successfully sent to email', 'Success');
      return true;
    } catch (e) {
      await updateObjectHandler('email-sender', { id: new Date().toISOString(), email, filesIds });
      dispatch(postEmailFilesFailure(e));
      return false;
    }
  };
};
