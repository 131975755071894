import axiosApi from '../../utils/axios';
import {
  GET_CLINICAL_PAPERS_FAILURE,
  GET_CLINICAL_PAPERS_REQUEST,
  GET_CLINICAL_PAPERS_SUCCESS,
  GET_FOLDER_BY_ID_FAILURE,
  GET_FOLDER_BY_ID_REQUEST,
  GET_FOLDER_BY_ID_SUCCESS,
  GET_FOLDERS_FAILURE,
  GET_FOLDERS_REQUEST,
  GET_FOLDERS_SUCCESS,
  PDF_UPLOAD,
} from '../actionTypes';
import { get } from '../../utils/idb-config';
import { compareNumbers, starsRatingLocal } from '../../utils/functions';
import { controller } from '../../utils/axiosController';

const getFoldersRequest = () => ({ type: GET_FOLDERS_REQUEST });
const getFoldersSuccess = (folders) => ({ type: GET_FOLDERS_SUCCESS, folders });
const getFoldersFailure = (error, folders) => ({ type: GET_FOLDERS_FAILURE, error, folders });

const getFoldersByIdRequest = () => ({ type: GET_FOLDER_BY_ID_REQUEST });
const getFoldersByIdSuccess = (files) => ({ type: GET_FOLDER_BY_ID_SUCCESS, files });
const getFoldersByIdFailure = (error, files) => ({ type: GET_FOLDER_BY_ID_FAILURE, error, files });

const getClinicalPapersRequest = () => ({ type: GET_CLINICAL_PAPERS_REQUEST });
const getClinicalPapersSuccess = (folders) => ({ type: GET_CLINICAL_PAPERS_SUCCESS, folders });
const getClinicalPapersFailure = (error, folders) => ({ type: GET_CLINICAL_PAPERS_FAILURE, error, folders });

export const getFolders = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.user.token;
    const stars = (await get('folders-stars')) || [];
    try {
      dispatch(getFoldersRequest());
      const folders = (await get('folders')) || [];
      if (folders.length) dispatch(getFoldersSuccess(starsRatingLocal(folders, stars).sort(compareNumbers)));
      else {
        const response = await axiosApi.get(`/folders/getFolders?token=${token}`, { signal: controller.signal });
        dispatch(getFoldersSuccess(starsRatingLocal(response.data.body.folders, stars).sort(compareNumbers)));
      }
    } catch (error) {
      const response = await get('folders');
      dispatch(getFoldersFailure(error, starsRatingLocal(response, stars).sort(compareNumbers)));
    }
  };
};

export const getFileByFolder = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.user.token;
    const stars = (await get(`file-stars-${id}`)) || [];
    try {
      dispatch(getFoldersByIdRequest());
      const files = (await get(`fileByFolder-${id}`)) || [];
      if (files.length) dispatch(getFoldersByIdSuccess(starsRatingLocal(files, stars)).sort(compareNumbers));
      else {
        const response = await axiosApi.get(`/files/getFiles?token=${token}&folder_id=${id}`, { signal: controller.signal });
        await dispatch(getFoldersByIdSuccess(starsRatingLocal(response.data.body.files, stars).sort(compareNumbers)));
      }
    } catch (error) {
      const response = await get(`fileByFolder-${id}`);
      dispatch(getFoldersByIdFailure(error, starsRatingLocal(response, stars).sort(compareNumbers)));
    }
  };
};

export const getClinicalPapers = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.user.token;
    const stars = (await get('clinical-papers-stars')) || [];
    try {
      dispatch(getClinicalPapersRequest());
      const clinicalPapers = (await get('clinicalPapers')) || [];
      if (clinicalPapers.length) dispatch(getClinicalPapersSuccess(starsRatingLocal(clinicalPapers, stars).sort(compareNumbers)));
      else {
        const response = await axiosApi.get(`/files/getClinicalPapers?token=${token}`, { signal: controller.signal });
        dispatch(getClinicalPapersSuccess(starsRatingLocal(response.data.body?.clinical_papers?.new.folders, stars).sort(compareNumbers)));
      }
    } catch (error) {
      const response = await get('clinicalPapers');
      dispatch(getClinicalPapersFailure(error, starsRatingLocal(response, stars).sort(compareNumbers)));
    }
  };
};

export const viewPdf = (pdf) => ({ type: PDF_UPLOAD, pdf });
