import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { postReportClinicalPapers, postReportFile } from '../../store/actions/reportActions';
import ReactPlayer from 'react-player';
import { imgURL } from '../../utils/config';
import history from '../../utils/history';
import { CloseOutlined } from '@ant-design/icons';
import { get } from '../../utils/idb-config';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import './styles.scss';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfViewer = (props) => {
  const id = props.match.params.id;
  const dispatch = useDispatch();
  const pdfState = useSelector((state) => state.folders.pdf);
  const [pdf, setPdf] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [show, setShow] = useState(false);
  const width = useResizeWindows();
  const pdfRef = useRef();

  useEffect(() => {
    if (width < 1250) setShow(true);
  }, [width]);

  useEffect(() => {
    const startDate = Date.now();
    return () => {
      if (id) dispatch(postReportFile({ startDate, id, endDate: Date.now() }));
      else dispatch(postReportClinicalPapers({ startDate, id, endDate: Date.now() }));
    };
  }, [dispatch, id]);

  useEffect(() => {
    const effect = async () => {
      if (!Object.keys(pdfState).length) {
        const files = (await get('files-all-folders')) || [];
        setPdf(files.filter((item) => item.id === id)[0] || {});
      } else {
        setPdf(pdfState);
      }
    };

    effect().then((r) => r);
  }, [pdfState, id]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const memoUrl = useMemo(() => {
    return pdf.localPathBase64 ? pdf.localPathBase64 : !!pdf.localPath ? URL.createObjectURL(pdf.localPath) : imgURL + pdf.path;
  }, [pdf]);
  return (
    <>
      <div className={show ? 'nav-top-active' : 'nav-top'} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        <div className="nav-top__item" onClick={() => history.goBack()}>
          <CloseOutlined className="nav__item-icon" />
          <p>Close</p>
        </div>
      </div>

      <div className="pdf" ref={pdfRef}>
        {Object.keys(pdf).length ? (
          <>
            {pdf.type === 'pdf' ? (
              <Document
                onLoadError={console.error}
                file={{
                  url: memoUrl,
                }}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                  <div className="spinner-container">
                    <Spin spinning className="spinner" size="large" />
                  </div>
                }
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page scale={1} className="pdf__page" key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            ) : pdf.type === 'image' ? (
              <img
                className=""
                src={imgURL + pdf.thumbnail}
                alt="pdf"
                onError={(e) => (e.target.src = pdf.localUrl ? URL.createObjectURL(pdf.localUrl) : null)}
              />
            ) : (
              <ReactPlayer
                controls
                playing
                width={'100%'}
                height="100%"
                url={pdf.localUrl ? URL.createObjectURL(pdf.localUrl) : imgURL + pdf.path}
                draggable={false}
              />
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default memo(PdfViewer);
