import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons';
import Card from '../../Components/Card.js/Card';
import { Spin } from 'antd';
import Footer from '../../Components/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getClinicalPapers } from '../../store/actions/foldersActions';
import { compareNumbers, starsRatingLocal } from '../../utils/functions';
import { get } from 'idb-keyval/dist/index';
import { AppContext } from '../../utils/context';

const ClinicalPapersFile = (props) => {
  const id = props.match.params.id;
  const dispatch = useDispatch();
  const folders = useSelector((state) => state.folders?.clinicalPapers);
  const signal = useSelector((state) => state.signal?.clinicalFolder);
  const loading = useSelector((state) => state.folders.loading);
  const [stars, setStars] = useState([]);
  const [show, setShow] = useState(false);
  const { setEmailSender, emailSender } = useContext(AppContext);

  const effect = useCallback(async () => {
    const localStar = (await get(`clinical-file-stars-${id}`)) || [];
    setStars(localStar);
  }, [id]);

  useEffect(() => {
    dispatch(getClinicalPapers());

    const effectHandler = async () => {
      const localStar = (await get(`clinical-file-stars-${id}`)) || [];
      setStars(localStar);
    };

    effectHandler().then((r) => r);
  }, [dispatch, id]);

  useEffect(() => {
    if (signal) {
      dispatch(getClinicalPapers());

      const effectHandler = async () => {
        const localStar = (await get(`clinical-file-stars-${id}`)) || [];
        setStars(localStar);
      };

      effectHandler().then((r) => r);
    }
  }, [dispatch, id, signal]);

  const files = useMemo(() => {
    if (!loading) return folders.filter((folder) => folder.id === id)[0]?.files;
    else return [];
  }, [id, folders, loading]);

  const fileName = useMemo(() => {
    return folders.filter((folder) => folder.id === id)[0]?.name || '';
  }, [id, folders]);

  const cardBlock = useMemo(() => {
    return files && files.length
      ? starsRatingLocal(files, stars)
          .sort(compareNumbers)
          .map((file, index) => (
            <Card
              file={file}
              path="pdf"
              id={file.id}
              key={file.id + index}
              url={file.thumbnail}
              title={file.name}
              date={file.date_published}
              pdfUrl={file.localPathBase64 || file.path}
              localUrl={file.localLogoBase64 || file?.localUrl}
              starsDb={`clinical-file-stars-${id}`}
              fileId={id}
              effect={effect}
              download={file}
              description={file.subject}
            />
          ))
      : null;
  }, [files, id, effect, stars]);

  return (
    <div className="content">
      <div className="main-block">
        <div
          className={show && !emailSender ? 'nav-top-active' : 'nav-top'}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <div className="nav-top__item" onClick={() => setEmailSender(true)}>
            <MailOutlined className="nav__item-icon" />
            <p>Email</p>
          </div>
        </div>
        <h1>
          <Link to={'/files'}>
            <ArrowLeftOutlined className="back-icon" />
          </Link>
          Clinical Papers / {fileName}
        </h1>
        <div className="main-block__content">{cardBlock}</div>
      </div>
      {loading && (
        <div className="main-block__spinner-container">
          <Spin spinning className="spinner" size="large" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default memo(ClinicalPapersFile);
