import React, { useEffect, useState } from 'react';
import './styles.scss';

const EDetailerNavigationTop = ({ e_detailer = [], state, setState, slides }) => {
  const [currentTab, setCurrentTab] = useState(e_detailer.home_slide_id || slides[0]?.id);
  const navigationHandler = (id) => {
    const index = slides.filter((item) => item.id === id)[0].index;
    setState(index);
    setCurrentTab(id);
  };

  useEffect(() => {
    document.addEventListener('keydown', event => {
      event.preventDefault();
      event.stopPropagation();
      if (event.key === 'ArrowRight') setState((prev) => (prev + 1 < slides.length ? prev + 1 : prev));
      else if (event.key === 'ArrowLeft') setState((prev) => (prev - 1 > 0 ? prev - 1 : 0));
    })
  }, [setState,slides.length]);


  useEffect(() => {
    const id = slides.filter((item) => item.index === state)[0]?.id;
    if (id) setCurrentTab(id);
  }, [e_detailer, slides, state]);

  return (
    <div className="e-detailer-top-nav" style={{backgroundColor: e_detailer?.colors?.color_header_background ?? '#185b97'}}>
      <div className="e-detailer-top-nav__inner">
        {Object.keys(e_detailer).length
          ? e_detailer?.tabs.map((item, index) => (
              <button
                key={index}
                className="e-detailer-top-nav__item"
                onClick={() => navigationHandler(item.slide_id)}
                disabled={currentTab === item.slide_id}
                style={{
                  color: currentTab === item.slide_id ? e_detailer.colors.color_used_words : e_detailer.colors.color_unused_words,
                  background:
                    currentTab === item.slide_id ? e_detailer.colors.color_used_background : e_detailer.colors.color_unused_background,
                }}
              >
                {item.title}
              </button>
            ))
          : null}
      </div>
    </div>
  );
};

export default EDetailerNavigationTop;
