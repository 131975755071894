import {
  GET_CLINICAL_PAPERS_FAILURE,
  GET_CLINICAL_PAPERS_REQUEST,
  GET_CLINICAL_PAPERS_SUCCESS,
  GET_FOLDER_BY_ID_FAILURE,
  GET_FOLDER_BY_ID_REQUEST,
  GET_FOLDER_BY_ID_SUCCESS,
  GET_FOLDERS_FAILURE,
  GET_FOLDERS_REQUEST,
  GET_FOLDERS_SUCCESS,
  LOGOUT_USER,
  PDF_UPLOAD,
} from '../actionTypes';

export const initialState = {
  folders: [],
  clinicalPapers: [],
  files: [],
  pdf: {},
  error: null,
  loading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOLDERS_REQUEST:
      return { ...state, loading: true, error: null, folders: [] };
    case GET_FOLDERS_SUCCESS:
      return { ...state, loading: false, error: null, folders: action.folders };
    case GET_FOLDERS_FAILURE:
      return { ...state, loading: false, error: action.error, folders: action.folders };
    case GET_FOLDER_BY_ID_REQUEST:
      return { ...state, loading: true, files: [], error: null };
    case GET_FOLDER_BY_ID_SUCCESS:
      return { ...state, loading: false, error: null, files: action.files };
    case GET_FOLDER_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.error, files: action.files };
    case GET_CLINICAL_PAPERS_REQUEST:
      return { ...state, loading: true, error: null, clinicalPapers: [] };
    case GET_CLINICAL_PAPERS_SUCCESS:
      return { ...state, loading: false, error: null, clinicalPapers: action.folders };
    case GET_CLINICAL_PAPERS_FAILURE:
      return { ...state, loading: false, error: action.error, clinicalPapers: action.folders };
    case PDF_UPLOAD:
      return { ...state, pdf: action.pdf };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default authReducer;
