import React from 'react';
import Image from '../Image/Image';
import './styles.scss';

const NavigationBottom = ({ state, notes, show, setShow, slides, setState }) => {
  return (
    <>
      {slides[state]?.type !== 'video' && (
        <nav className={show ? 'nav-bottom-active' : 'nav-bottom'} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
          {show &&
            slides.map((slide, index) => (
              <div className={`nav-bottom-block ${notes ? 'nav-bottom-block-notes' : ''}`} key={index}>
                <Image
                  videoPreview={slide?.preview_url}
                  src={slide.type === 'video' || slide.type === 'pdf' ? slide.preview_url : slide.url}
                  localUrl={
                    slide.localPreview
                      ? URL.createObjectURL(slide.localPreview)
                      : slide.localUrlBase64
                      ? slide.localUrlBase64
                      : slide.localUrl
                      ? URL.createObjectURL(slide.localUrl)
                      : null
                  }
                  onClick={() => setState(index)}
                />
                <p>
                  <span>{index + 1}. </span>
                  {slide.title}
                </p>
              </div>
            ))}
        </nav>
      )}
    </>
  );
};

export default NavigationBottom;
