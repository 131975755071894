import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Login from './Pages/Login/Login';
import { useDispatch, useSelector } from 'react-redux';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Presentations from './Pages/Presentations/Presentations';
import Presentation from './Pages/Presentation/Presentation';
import { useFonts } from './utils/hooks/useFonts';
import { loadFromLocalStorage } from './utils/localStorage';
import Folders from './Pages/Folders/Folders';
import ClinicalPapers from './Pages/ClinicalPapers/ClinicalPapers';
import Videos from './Pages/Videos/Videos';
import Custom from './Pages/Custom/Custom';
import Search from './Pages/Search/Search';
import Files from './Pages/Files/Files';
import PDFViewer from './Components/PDFViewer/PDFViewer';
import ClinicalPapersFile from './Pages/ClinicalPapersFile/ClinicalPapersFile';
import { notification } from 'antd';
import InstallPWA from './Install';
import { getAll } from './store/actions/searchActions';
import { get } from './utils/idb-config';
import { postReportFile, postReportPresentation } from './store/actions/reportActions';
import { setHandler } from './utils/functions';
import { viewPort } from './utils/hooks/useViewPort';
import { AppContextProvider } from './utils/context';
import SendEmail from './Components/SendEmail/SendEmail';
import './styles/main.scss';
import { postEmail } from './store/actions/senderActions';
import Edetailer from './Pages/Edetailer/Edetailer';
import EdetailerPresentation from './Pages/EdetailerPresentation/EdetailerPresentation';

export const showNotification = (type, text, title) => {
  notification[type]({
    message: title,
    description: text,
    className: 'notification-border',
  });
};

export const ProtectedRoute = ({ isAllowed, redirectTo, ...props }) => {
  if (isAllowed) {
    return (
      <LazyLoad>
        <Route {...props} />
      </LazyLoad>
    );
  }

  return null;
};

const App = () => {
  const dispatch = useDispatch();
  const { fonts, setFonts } = useFonts();
  const user = useSelector((state) => state?.auth?.user);
  const [emailSender, setEmailSender] = useState(false);
  const [emailSearch, setEmailSearch] = useState(false);

  useEffect(() => {
    const checkOnlineState = async () => {
      if (navigator.onLine && user) {
        const file = (await get('report-files')) || [];
        const presentations = (await get('presentations-report')) || [];
        const emailSender = (await get('email-sender')) || [];
        if (file?.length) {
          for (let i = 0; i < file.length; i++) {
            dispatch(postReportFile({ id: file[i].id, endDate: file[i].endDate, startDate: file[i].startDate }));
          }
          await setHandler('report-files', []);
        }

        if (presentations.length) {
          for (let i = 0; i < file.length; i++) {
            dispatch(
              postReportPresentation({
                id: presentations[i].id,
                startDate: presentations[i].startDate,
                endDate: presentations[i].endDate,
                quantity: presentations[i].quantity.length,
                order: presentations[i].order,
              })
            );
          }

          await setHandler('presentations-report', []);
        }

        if (emailSender.length) {
          for (let i = 0; i < emailSender.length; i++) {
            dispatch(postEmail(emailSender[i].email, emailSender[i].filesIds, false));
          }

          await setHandler('email-sender', []);
        }
      }
    };
    window.addEventListener('online', checkOnlineState);

    checkOnlineState().then((r) => r);
  }, [dispatch, user]);

  useEffect(() => {
    setFonts(loadFromLocalStorage('fonts'));
  }, [fonts, setFonts]);

  useEffect(() => {
    if (user) dispatch(getAll());
    viewPort();
  }, [dispatch, user]);

  return (
    <AppContextProvider emailSender={emailSender} setEmailSender={setEmailSender} emailSearch={emailSearch} setEmailSearch={setEmailSearch}>
      <InstallPWA />
      {!user ? (
        <Switch>
          <ProtectedRoute exact path="/" component={Login} isAllowed={!user} />
          <ProtectedRoute path="/reset-password/:token" component={ResetPassword} isAllowed={!user} />
          <ProtectedRoute path="/forgot-password" component={ForgotPassword} isAllowed={!user} />
          <Redirect to={!user ? '/' : '/presentations'} />
        </Switch>
      ) : (
        <>
          <Switch>
            <ProtectedRoute exact path="/presentations" component={Presentations} isAllowed={user} />
            <ProtectedRoute path="/presentations/view/:id" component={Presentation} isAllowed={user} />
            <ProtectedRoute path="/presentations/custom/view/:id" component={Presentation} isAllowed={user} />
            <ProtectedRoute exact path="/files" component={Folders} isAllowed={user} />
            <ProtectedRoute path="/files/view/:id" component={Files} isAllowed={user} />
            <ProtectedRoute exact path="/clinical-papers" component={ClinicalPapers} isAllowed={user} />
            <ProtectedRoute path="/clinical-papers/view/:id" component={ClinicalPapersFile} isAllowed={user} />
            <ProtectedRoute path="/pdf/view/:id" component={PDFViewer} isAllowed={user} />
            <ProtectedRoute exact path="/video" component={Videos} isAllowed={user} />
            <ProtectedRoute exact path="/e-detailer" component={Edetailer} isAllowed={user} />
            <ProtectedRoute path="/e-detailer/view/:id" component={EdetailerPresentation} isAllowed={user} />
            <ProtectedRoute exact path="/custom" component={Custom} isAllowed={user} />
            <ProtectedRoute exact path="/search" component={Search} isAllowed={user} />
            <Redirect to={user ? '/presentations' : '/'} />
          </Switch>
          {emailSender && <SendEmail />}
        </>
      )}
    </AppContextProvider>
  );
};

export default App;

// admin@sales-flow.net
// QWEiop123$
